import React, { useState } from 'react';
import CarLeases from '../../components/CarLeases/index.js';
import PickandDrop from '../../components/PickAndDrop/index.js';
import SideDrawer from '../../components/SideDrawer/index.js';
import { useLocation } from 'react-router';

function CurrentLeases(props) {
  // const [change, setChange] = useState("current")
  // const [active, setActive] = useState(false);
  const location = useLocation();
  console.log(location.pathname);
  return (
    <div style={{ backgroundColor: '#F9F9F9' }}>
    <div className="container-fluid" style={{backgroundColor: 'whitesmoke', height: '1rem', opacity: '0%'}}></div>
      <div className="container-fluid mt-5" style={{ backgroundColor: 'white' }}>
        <div className="row">
          <div classNam="col-xl-12">
            <CarLeases />
          </div>
        </div>
        {/* <div className="container">
              <PickandDrop />
            </div> */}
      </div>
    </div>
  )
}

export default CurrentLeases;
