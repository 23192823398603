import React, {useState} from 'react';

function Buttons(props){
  const [variant] = useState(props.variant);
  const {style, onClick, className, type, toggle, target} =props;
  return(
    <button
     className={`button-${variant}`, `button-${className}`}
     style={style}
     onClick={onClick}
     type={type}
     data-toggle={toggle}
     data-target={target}
    >
    {props.children}
    </button>
  );
}

export default Buttons;