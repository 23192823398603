import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import BottomTabs from '../../components/BottomTabs'
import StartDateTime from '../../components/StartDateTime';
import SUV from '../../components/SUV/index.js';

function Vehicles() {
  return (
    <>
      <div className="container-fluid" style={{backgroundColor: '#F9F9F9'}}>
        <Row className="">
          <Col className="">
            {/* <SUV /> */}
            <BottomTabs />
          </Col>
        </Row>
      </div>
      <div>
        {/* <StartDateTime /> */}
      </div>
    </>

  )
}


export default Vehicles;
