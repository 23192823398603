import React,{useState} from 'react';
import { Row } from 'react-bootstrap';
import '../Filter/index.css';

function Filter() {
  const [active, setActive] = useState("suv");
  return (
    <div className="col card" style={{ border: 'none', width: '', boxShadow: 'none', backgroundColor: 'white' }}>

      {
        active === 'suv'
          ?
          <article className="filter-group" style={{ width: '100%', maxWidth: '100%', height: 'auto' }}>
            <header className="card-header">
              <a href="#" data-toggle="collapse" data-target="#collapse_aside1" data-abc="true" aria-expanded="false" className="collapsed">
                <i className="icon-control fa fa-chevron-down" style={{ color: 'orange' }}></i>
                <h6 className="title font-ullight d-flex" style={{ fontSize: '12px', color: '#656565', fontWeight: '400', justifyContent: 'center', marginLeft: '20px' }}>Car Model </h6>
              </a>
            </header>
            <div className="filter-content collapse" id="collapse_aside1" style={{ color: 'orange', }}>
              <div className="card-body" style={{ width: '' }}>
                <ul className="list-menu" style={{ marginTop: '-1rem' }}>
                  <hr style={{ opacity: '80%', color: '#949494', backgroundColor: '#949494' }}></hr>
                  <li><a href="#" data-abc="true" className="font-ullight d-flex" style={{ color: '#F28F31', fontSize: '10px', justifyContent: 'center' }}>2021</a></li>
                  <hr style={{ opacity: '80%', color: '#949494', backgroundColor: '#949494' }}></hr>
                  <li><a href="#" data-abc="true" className="font-ullight d-flex" style={{ color: '#656565', fontSize: '10px', justifyContent: 'center' }}>2020</a></li>
                  <hr style={{ opacity: '80%', color: '#949494', backgroundColor: '#949494' }}></hr>
                  <li><a href="#" data-abc="true" className="font-ullight d-flex" style={{ color: '#656565', fontSize: '10px', justifyContent: 'center' }}>2019</a></li>
                  <hr style={{ opacity: '80%', color: '#949494', backgroundColor: '#949494' }}></hr>
                  <li><a href="#" data-abc="true" className="font-ullight d-flex" style={{ color: '#656565', fontSize: '10px', justifyContent: 'center' }}>2018</a></li>
                  <hr style={{ opacity: '80%', color: '#949494', backgroundColor: '#949494' }}></hr>
                  <li><a href="#" data-abc="true" className="font-ullight d-flex" style={{ color: '#656565', fontSize: '10px', justifyContent: 'center' }}>2017</a></li>
                  <hr style={{ opacity: '80%', color: '#949494', backgroundColor: '#949494' }}></hr>
                  <li><a href="#" data-abc="true" className="font-ullight d-flex" style={{ color: '#656565', fontSize: '10px', justifyContent: 'center' }}>2016</a></li>
                  <hr style={{ opacity: '80%', color: '#949494', backgroundColor: '#949494' }}></hr>
                  <li><a href="#" data-abc="true" className="font-ullight d-flex" style={{ color: '#656565', fontSize: '10px', justifyContent: 'center' }}>2015</a></li>
                  <hr style={{ opacity: '80%', color: '#949494', backgroundColor: '#949494' }}></hr>
                  <li><a href="#" data-abc="true" className="font-ullight d-flex" style={{ color: '#656565', fontSize: '10px', justifyContent: 'center' }}>2014</a></li>
                </ul>
              </div>
            </div>
          </article>
          :
          null
      }
      <article className="filter-group" style={{ width: '100%', maxWidth: '100%', height: 'auto' }}>
        <header className="card-header">
          <h6 className="title font-ullight" style={{ fontSize: '12px', color: '#656565', fontWeight: '400', textAlign: 'center' }}>Price range </h6>
        </header>
      </article>
      <article className="filter-group" style={{ width: '100%', maxWidth: '100%', height: 'auto' }}>
        <header className="card-header">
          <div className="row">
            <div className="col-6 d-flex" style={{ justifyContent: 'flex-start' }}>
              <p className="font-ullight" style={{ fontSize: '8px', color: 'orange' }}>$0.00</p>
            </div>
            <div className="col-6 d-flex" style={{ justifyContent: 'flex-end' }}>
              <p className="font-ullight" style={{ fontSize: '8px', color: 'orange' }}>$1000.00</p>
            </div>
          </div>
        </header>
      </article>
    </div>
  )
}

export default Filter;
