import React, { useState } from 'react';
import Inputs from '../inputs/index.js';
import Dropdown from '../dropdown/index.js';
import Buttons from '../Buttons/index.js';
import '../BookNowTab/index.css';

function BookNowTab() {

  // const [removeInput, addInput] = useState(false);
  const data = [
    {
      id: '1',
      name: '+93'
    },
    {
      id: '2',
      name: '+94'
    },
    {
      id: '3',
      name: '+95'
    },
  ];

  return (
    <>
      <div className="row d-flex mt-4" style={{ justifyContent: 'center', width: '100%', maxWidth: '100%' }}>
        <div className="col-11" style={{ backgroundColor: 'white', boxShadow: '0px 2px 6px rgb(231, 231, 231)', borderRadius: '8px' }}>
          <div className="row mt-4 mb-4">
            <div className="col mt-4 d-flex">
              <p className="font-medium" style={{ opacity: '90%', color: '#656565' }}>Location</p>
              <span
                class="fa fa-search field-icon"
                style={{ color: "orange", top: '-1px', position: 'relative', zIndex: '2', right: '-26px' }}
              ></span>
              <Inputs
                type={"text"}
                placeholder={""}
                name="input-form"
                className="inputclass"
                style={{
                  marginTop: '-0px',
                  width: '14rem',
                  height: '20px',
                  border: '0',
                  boxShadow: '-5px 9px 0px -7px #949494, 5px 9px 0px -7px #949494',
                  outline: 'none',
                  color: '#656565'
                }}
              />
            </div>
            <div className="col mt-4 d-flex">
              <p className="font-medium" style={{ opacity: '90%', color: '#656565' }}>Pick Up Date</p>
              <Inputs
                type={"date"}
                value=" "
                placeholder={"   "}
                name="input-form"
                className="inputclass"
                style={{
                  marginTop: '-10px',
                  width: '14rem',
                  height: '30px',
                  border: '0',
                  boxShadow: '-5px 9px 0px -7px #949494, 5px 9px 0px -7px #949494',
                  outline: 'none',
                  lineheight: '50px',
                  paddingTop: '8px',
                  paddingBottom: '15px',
                  color: '#656565'
                }}
              />
              <span className="fa fa-fw fa-angle-down field-icon" id="togglePassword" type="button" style={{ color: '#00000099' }}></span>
            </div>
            <div className="col mt-4 d-flex">
              <p className="font-medium" style={{ opacity: '90%', color: '#656565' }}>Pick Up Time</p>
              <Inputs
                type={"date"}
                value=" "
                placeholder={"  "}
                name="input-form"
                className="inputclass"
                style={{
                  marginTop: '-0px',
                  width: '5rem',
                  height: '20px',
                  border: '0',
                  boxShadow: '-3px 9px 0px -7px #949494, 3px 9px 0px -7px #949494',
                  outline: 'none',
                  color: '#656565'
                  // padding: '12px',
                }}
              />
              <span className="fa fa-fw fa-angle-down field-icon" id="togglePassword" type="button" style={{ color: '#00000099' }}></span>
              <Inputs
                type={"date"}
                value=" "
                placeholder={""}
                name="input-form"
                className="inputclass"
                style={{
                  marginTop: '-0px',
                  width: '5rem',
                  height: '20px',
                  // borderRadius: '5px',
                  // backgroundColor: '#F6F6F6',
                  border: '0',
                  boxShadow: '-3px 9px 0px -7px #949494, 3px 9px 0px -7px #949494',
                  outline: 'none',
                  color: '#656565'
                }}
              />
              <span className="fa fa-fw fa-angle-down field-icon" id="togglePassword" type="button" style={{ color: '#00000099' }}></span>
              <Inputs
                type={"date"}
                value=" "
                placeholder={""}
                name="input-form"
                className="inputclass"
                style={{
                  marginTop: '-0px',
                  width: '5rem',
                  height: '20px',
                  // borderRadius: '5px',
                  // backgroundColor: '#F6F6F6',
                  border: '0',
                  boxShadow: '-3px 9px 0px -7px #949494, 3px 9px 0px -7px #949494',
                  outline: 'none',
                  color: '#656565'
                }}
              />
              <span className="fa fa-fw fa-angle-down field-icon" id="togglePassword" type="button" style={{ color: '#00000099' }}></span>
            </div>
            <div className="col d-flex font-medium" style={{ justifyContent: 'center' }}>
              <Buttons
                type={"button"}
                style={{
                  width: '205px',
                  height: '74px',
                  backgroundColor: '#F28F31',
                  border: 'none',
                  outline: 'none',
                  borderRadius: '6px',
                  color: 'white',
                  fontSize: '20px'
                }}
              >
                Book Now
              </Buttons>
              {/* <button class="btn btn-outline-success" type="submit" style={{ width: '10rem', backgroundColor: '#F28F31', color: 'white', border: '0rem', borderRadius: '8px', alignItems: 'initial', }}>Book Now</button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BookNowTab
