import React from 'react';
import slidercar from '../../assets/images/slidercar.png';
import { NavLink } from 'react-router-dom';
import tick from '../../assets/images/tick.svg';
import Inputs from '../inputs/index.js';
import Buttons from '../Buttons/index.js';
import Dropdowns from '../dropdown/index.js';
import '../invoice/index.css';

function Invoice(props) {
  const {src, title, titledetails, rentprice, renttime} = props;
  const expdate = [
    {
      id: '1',
      name: '+92'
    },
    {
      id: '2',
      name: '+93'
    },
    {
      id: '3',
      name: '+94'
    },
  ];
  return (
    <>
      <div style={{ backgroundColor: "whitesmoke" }}>
        <div className="container-fluid" style={{ backgroundColor: 'white' }}>
          <div className="row">
            <div className="col-xl-2" style={{ backgroundColor: '' }}>
            </div>
            <div className="col-xl-4" style={{ backgroundColor: '' }}>
              <div className="row mt-3">
                <div className="col d-flex" style={{ justifyContent: 'center' }}>
                  <img src={src} style={{ width: '18rem', maxWidth: '18rem', maxHeight: '10rem', marginTop: '1.5rem', objectFit: 'contain' }} />
                </div>
              </div>
              <div className="row mt-3" style={{ justifyContent: '' }}>
                <div className="col" style={{}}>
                  <div className="row">
                    <div className="col" style={{ justifyContent: '' }}>
                      <div className="row">
                        <p className="font-bold" style={{ fontSize: '20px', color: '#5A5A5A', textAlign: 'center' }}>{title}</p>
                      </div>
                      <div className="row">
                        <p className="ml-2 font-ullight" style={{ fontSize: '12px', color: '#9B9B9B', textAlign: 'end', marginTop: '-1rem' }}>({titledetails}<a style={{ textDecoration: 'underline' }}>view all features</a>)</p>
                      </div>
                    </div>
                    <div className="col" style={{ justifyContent: '' }}>
                      <div className="row">
                        <p className="font-bold" style={{ fontSize: '20px', color: '#5A5A5A', textAlign: '' }}>{rentprice}</p>
                      </div>
                      <div className="row">
                        <p className="font-ullight" style={{ fontSize: '12px', color: '#9B9B9B', textAlign: '', marginTop: '-1rem' }}>{renttime}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col">
                  <p className="ml-4 font-medium" style={{ fontSize: '14px', color: '#666666', textAlign: 'center', }}>Rental Cost</p>
                </div>
                <div className="col">
                  <p className="font-ullight" style={{ fontSize: '14px', color: '#666666', textAlign: 'center' }}>$500</p>
                </div>
              </div>
              <div className="row" style={{ marginTop: '-0.5rem' }}>
                <div className="col">
                  <p className="mr-3 font-medium" style={{ fontSize: '14px', color: '#666666', textAlign: 'center', }}>Taxes</p>
                </div>
                <div className="col">
                  <p className="font-ullight" style={{ fontSize: '14px', color: '#666666', textAlign: 'center' }}>$400</p>
                </div>
              </div>
              <div className="row" style={{ marginTop: '-1rem' }}>
                <div className="col d-flex" style={{ justifyContent: 'center' }}>
                  <hr className="mr-2" style={{ color: '#707070', height: '1.8px', backgroundColor: '#707070', opacity: '80%', width: '18.3rem', marginLeft: '' }}></hr>
                </div>
              </div>
              <div className="row mt-1">
                <div className="col">
                  <p className="ml-5 font-medium" style={{ fontSize: '14px', color: '#F28F31', textAlign: 'center', }}>Total Ammount</p>
                </div>
                <div className="col">
                  <p className="font-ullight" style={{ fontSize: '14px', color: '#666666', textAlign: 'center' }}>$900</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 colpad" style={{ backgroundColor: '' }}>
              <div className="row mt-5">
                <div className="col d-flex mt-1" style={{justifyContent: 'start'}}>
                  <p className="font-medium" style={{ fontSize: '14px', color: '#666666', textAlign: 'start' }}>Enter Payment Details</p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col">
                  <Inputs
                    className={"font-ullight"}
                    type="text"
                    placeholder="Card Holder"
                    style={{
                      width: '321px',
                      height: '51px',
                      backgroundColor: '#F6F6F6',
                      border: 'none',
                      outline: 'none',
                      borderRadius: '6px',
                      color: '#9B9B9B',
                      fontSize: '14px',
                      padding: '15px'
                    }} />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <Inputs
                  className={"font-ullight"}
                    type="text"
                    placeholder="Card Number"
                    style={{
                      width: '321px',
                      height: '51px',
                      backgroundColor: '#F6F6F6',
                      border: 'none',
                      outline: 'none',
                      borderRadius: '6px',
                      color: '#9B9B9B',
                      fontSize: '14px',
                      padding: '15px'
                    }} />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-4 col-md-3" style={{ justifyContent: '' }}>
                  <div className="row">
                    <p className="font-ullight" style={{ fontSize: '12px', color: '#9B9B9B', textAlign: '' }}>Exp. Date</p>
                  </div>
                  <div className="row">
                    <div className="col">
                      <Dropdowns
                        className={"font-ullight"}
                        data={expdate}
                        name="customSearch"
                        className="custom-search-select"
                        style={{
                          width: '110px',
                          height: '33px',
                          border: 'none',
                          borderRadius: '6px',
                          backgroundColor: '#F6F6F6',
                          fontSize: '12px',
                          color: '#9B9B9B',
                          outline: 'none'
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col" style={{ justifyContent: '' }}>
                  <div className="row">
                    <p className="font-ullight" style={{ fontSize: '12px', color: '#9B9B9B', textAlign: '' }}>CVV</p>
                  </div>
                  <div className="row">
                    <div className="col">
                      <Inputs
                      className={"font-ullight"}
                        type="text"
                        placeholder="CVV"
                        style={{
                          backgroundColor: '#F6F6F6',
                          border: 'none',
                          borderRadius: '6px',
                          width: '110px',
                          height: '33px',
                          fontSize: '12px',
                          color: '#9B9B9B',
                          padding: '6px',
                          outline: 'none',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col">
                  <button
                    className="font-medium"
                    data-target="#newModal"
                    data-toggle="modal"
                    style={{
                      width: '321px',
                      height: '52px',
                      borderRadius: '4px',
                      color: 'white',
                      backgroundColor: '#F28F31',
                      border: 'none',
                      outline: 'none',
                      fontSize: '20px'
                    }}>
                    Make Payment
                  </button>
                </div>
              </div>
            </div>
            <div className="col-xl-2" style={{ backgroundColor: '' }}>

            </div>

            <div
              className="modal fade"
              id="newModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
              data-backdrop="false"

            >
              <div
                class="modal-dialog modal-dialog-centered"
                role="document"

              >
                <div class="modal-content mb-5" style={{ width: '50rem', height: '20rem' }}>
                  <div class="modal-header" style={{ border: 'none', height: '1rem' }}>
                  </div>
                  <div class="modal-body" style={{ marginTop: '-1rem' }}>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="row">
                            <div className="col-xl-12 d-flex" style={{ justifyContent: 'center' }}>
                              <p className="font-bold" style={{ color: '#5A5A5A', fontSize: '29px' }}>Payment was successful</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-12 d-flex" style={{ justifyContent: 'center' }}>
                              <div>
                                <img
                                  class="d-block w-60"
                                  src={tick}
                                  alt="First slide"
                                  style={{ alignContent: 'center', display: 'block', height: '8rem' }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-xl-12 d-flex" style={{ justifyContent: 'center' }}>
                              <button
                                className="font-medium"
                                data-dismiss="modal"
                                onClick={() => props.humza("current")}
                                type="button"
                                style={{
                                  width: '321px',
                                  height: '52px',
                                  backgroundColor: "#f28f31",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "4px",
                                  outline: 'none',
                                  fontSize: '20px'
                                }}
                              >
                                Continue
                              </button>
                              {/* <Buttons
                              onClick={()}
                              type={"button"}
                              style={{
                                width: '298px',
                                height: '52px',
                                backgroundColor: '#F28F31',
                                border: 'none',
                                borderRadius: '4px',
                                color: 'white',
                                outline: 'none'
                              }}
                            >
                              Continue
                            </Buttons> */}
                              {/* <form class="d-flex">
                                <button
                                  data-dismiss="modal"
                                  onClick={() => }
                                  type="button"
                                  style={{
                                    width: "298px",
                                    height: "52px",
                                    backgroundColor: "#f28f31",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "4px",
                                    outline: 'none'
                                  }}
                                >
                                  <NavLink className="nav-link font-bold" to="/vehiclesfive" style={{ fontSize: '20px', color: 'white' }}>Continue</NavLink>
                                </button>
                              </form> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
      <br></br>
      <div className="container-fluid" style={{ backgroundColor: 'orange', height: '1px' }}></div>
      <div className="container-fluid" style={{ backgroundColor: 'whitesmoke', height: '2rem' }}></div>
      <div className="container-fluid" style={{ backgroundColor: '#949494', height: '1px' }}></div>
    </>
  )
}

export default Invoice
