import React from 'react'
import Inputs from '../inputs/index.js';
import Buttons from '../Buttons/index.js';
import Dropdown from '../dropdown/index.js';

function CurrentListingsEquipment(props) {
  const data = [
    {
      id: '1',
      name: '1',
    },
    {
      id: '2',
      name: '2',
    },
    {
      id: '3',
      name: '3',
    }
  ]
  return (
    <>
      <div style={{ backgroundColor: "whitesmoke" }}>
        <div className="container-fluid" style={{ backgroundColor: 'whitesmoke', height: '2rem' }}></div>
        <div className="container-fluid" style={{ backgroundColor: 'white' }}>
          <div className="row">
            <div className="col mt-4 d-flex" style={{ justifyContent: 'center' }}>
              <div className="row d-flex" style={{ justifyContent: 'center' }}>
                <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-0">
                  <div className="row mt-4">
                    <div className="col mt-4">
                      <h6 className="font-medium" style={{ fontWeight: '450', color: '#5A5A5A', fontSize: '20px' }}>
                        Current Listings
                      </h6>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col">
                    <Inputs
                        className={"font-ullight"}
                        type={"text"}
                        placeholder={"  Name"}
                        style={{
                          backgroundColor: '#F6F6F6',
                          borderRadius: '6px',
                          border: 'none',
                          outline: 'none',
                          width: '321px',
                          height: '51px',
                          fontSize: '14px',
                          color: '#9B9B9B',
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                    <Inputs
                    className={"font-ullight"}
                        type={"text"}
                        placeholder={"  Rent Cost"}
                        style={{
                          backgroundColor: '#F6F6F6',
                          borderRadius: '6px',
                          border: 'none',
                          outline: 'none',
                          width: '321px',
                          height: '51px',
                          fontSize: '14px',
                          color: '#9B9B9B',
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <label
                        className="font-ullight"
                        style={{
                          fontSize: "12px",
                          color: "#9B9B9B",
                        }}
                      >
                        Images of the equipment
                      </label>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col d-flex">
                    <input type="file"></input>
                      <Inputs

                        type={"text"}
                        placeholder={"  "}
                        style={{
                          backgroundColor: '#F6F6F6',
                          borderRadius: '6px',
                          border: 'none',
                          outline: 'none',
                          width: '321px',
                          height: '51px',
                          fontSize: '14px',
                          color: '#9B9B9B',
                        }}
                      />
                      <span
                        class="fa fa-plus-square field-icon"
                        style={{ color: "orange", top: "15px", float: 'left', zIndex: '2' }}
                      ></span>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <label
                        className="font-ullight"
                        style={{
                          fontSize: "12px",
                          // fontWeight: "bold",
                          color: "#9B9B9B",
                        }}
                      >
                        Available Quantity
                      </label>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col d-flex">
                      <Dropdown
                      className={"font-ullight"}
                        data={data}
                        style={{
                          width: '126px',
                          height: '33px',
                          color: '#9B9B9B',
                          fontSize: '12px',
                          backgroundColor: '#F6F6F6',
                          borderRadius: '6px',
                          border: 'none',
                          outline: 'none',
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-0 mt-5" style={{paddingLeft: '2rem'}}>
                  <div className="row mt-5">
                    <div className="col">
                      <label
                        className="font-ullight"
                        style={{
                          fontSize: "12pxpx",
                          marginTop: '-1rem',
                          color: "#9B9B9B",
                        }}
                      >
                        Description
                      </label>
                    </div>
                  </div>
                  <div className="row mt-0">
                    <div className="col">
                      <textarea
                        className="form-control rounded-5 font-ullight"
                        rows="16"
                        style={{
                          backgroundColor: "#F6F6F6",
                          width: "330px",
                          height: '307px',
                          // borderColor: "#F6F6F6",
                          border: 'none',
                          borderRadius: '6px',
                          fontSize: "12px",
                          color: "#9B9B9B",
                          fontWeight: "lighter",
                          outline: 'none'
                        }}
                        placeholder="   Description"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col mt-5 d-flex" style={{ justifyContent: 'center' }}>
              <Buttons
              className={"font-medium"}
                onClick={() => props.data("equip")}
                type={"button"}
                style={{
                  width: '321px',
                  height: '52px',
                  borderRadius: '4px',
                  border: 'none',
                  outline: 'none',
                  backgroundColor: '#F28F31',
                  color: 'white',
                  fontSize: '20px',
                }}
              >
                Continue
              </Buttons>
            </div>
          </div>
          <br></br>
          <br></br>
        </div>
        <div className="container-fluid" style={{ backgroundColor: 'orange', height: '1px' }}></div>
        <div className="container-fluid" style={{ backgroundColor: 'whitesmoke', height: '2rem' }}></div>
        <div className="container-fluid" style={{ backgroundColor: '#949494', height: '1px' }}></div>
      </div>
    </>
  )
}

export default CurrentListingsEquipment;
