import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import SimpleSteps from '../../components/3SimpleSteps';
import AboutUs from '../../components/AboutUs';
import AppButton from '../../components/AppButton';
import BookNowTab from '../../components/BookNowTab';
import CardsCategory from '../../components/CardsCategory/index.js';
import CardsEquipment from '../../components/CardsEquipment/index.js';
import Slider from '../../components/Slider';
import Button from '../../components/Buttons/index.js';
import ListVehicleBanner from '../../components/ListVehicleBanner';
import ListEquipmentBanner from '../../components/ListEquipmentBanner';
import DownloadUleaseAppBanner from '../../components/DownloadUleaseAppBanner';
import cardImg1 from '../../assets/images/Equipment1.png';
import cardImg2 from '../../assets/images/Equipment2.png';
import cardImg3 from '../../assets/images/Equipment3.png';
import cardImg4 from '../../assets/images/Equipment4.png';
import '../Home/index.css';

function Home() {
  return (
    <>
      <Container fluid>
        {/* <SideDrawer /> */}
        <Row>
          <Col xl-12
            className="fullimage"
            style={{
              position: 'relative',
              height: '55rem',
              color: 'white'
            }}
          >
            <div className="mt-5 py-5" >
              <Slider />
            </div>

            <div className=" apbtn"
              style={{
                display: '',
                position: 'absolute',
                paddingRight: '30px',
                marginTop: '70px'
                
              }}
            >
              <AppButton />
            </div>


          </Col>
          <div className="booknow"
            style={{
              position: '',
              // marginTop: '-5rem',
              width: '100%',
              maxWidth: '100%'
            }}>
            <BookNowTab />
          </div>
        </Row>

      </Container>

      <Container fluid>
        <Row>
          <Col xl-12>
            <div>
              <SimpleSteps />
            </div>
          </Col>
        </Row>
      </Container>

      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-xl-12">
              <AboutUs />
          </div>
        </div>
      </div>

      <Container fluid>
        <Row>
          <Col xl-12 mt-5>
            <div className="row mt-5">
              <div className="d-flex mt-5" style={{ justifyContent: 'center' }}>
                <h1 className="font-medium" style={{ fontSize: '20px', color: '#949494', }}>Car Listing</h1>
              </div>
              <div className="col d-flex" style={{ justifyContent: 'center' }}>
                <h1 className="font-bold" style={{ fontSize: '50px', color: '#404040',  textAlign: 'center' }}>Browse by category</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col xl-12>
            <CardsCategory />
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col>
            <div className="row mt-5">
              <div className="col d-flex mt-5" style={{ justifyContent: 'center' }}>
                <h1 className="font-bold" style={{ fontSize: '50px', fontWeight: 'bold', color: '#404040', textAlign: 'center' }}>Browse Equipments</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col xl-12>
            <CardsEquipment img1={[cardImg1, cardImg2, cardImg3, cardImg4]} title={"Equipment"}/>
          </Col>
        </Row>
      </Container>

      <Container fluid className="mt-5">
        <Row d-flex className="mt-5">
          <Col xl-12 className="mt-3 d-flex" style={{ justifyContent: 'center' }}>
          <NavLink to="/suv">
            <Button
              className={"font-bold"}
             style={{
              width: '205px',
              height: '72px',
              backgroundColor: '#F28F31',
              border: 'none',
              borderRadius: '9px',
              color: 'white'
            }}>
              View More
            </Button>
            </NavLink>
          </Col>
        </Row>
      </Container>

      <Container fluid className="mt-5">
        <Row className="" style={{ backgroundColor: '#F9F9F9', position: 'relative', marginTop: '10rem', height: '382px' }}>
          <Col className="mt-5 mb-5 px-5" style={{ width: '100%', maxWidth: '100%', height: 'auto' }}>
            <ListVehicleBanner />
          </Col>
        </Row>
      </Container>

      <Container fluid className="mt-5">
        <Row className="" style={{ backgroundColor: '#F9F9F9', position: 'relative', marginTop: '10rem', height: '382px' }}>
          <Col className="mt-5 mb-5 px-5" style={{ width: '100%', maxWidth: '100%', height: 'auto' }}>
            <ListEquipmentBanner />
          </Col>
        </Row>
      </Container>

      <Container fluid className="" style={{ marginTop: '20rem', marginBottom: '20rem' }}>
        <DownloadUleaseAppBanner />
      </Container>

      <Container fluid style={{ height: '0.1rem', backgroundColor: '#949494' }}>

      </Container>

    </>
  )
}

export default Home;
