import React, { useState } from 'react';
import PickandDrop from '../PickAndDrop/index.js';
import YourBooking from '../YourBooking/index.js';
import FeaturesVehicle from '../../components/FeaturesVehicle/index';
import FeaturesEquipment from '../../components/FeaturesEquipment/index';
import { useLocation } from 'react-router-dom';
import car from '../../assets/images/slidercar.png';
import crane from '../../assets/images/vehicle11.png';
import crane1 from '../../assets/images/Equipment6.png';
import generator from '../../assets/images/Equipment4.png';
import '../CarLeases/index.css';

function CarLeases() {

  const [pick, setPick] = useState('booking');
  const [active, setActive] = useState(false);
  return (
    <div>
      <div className="container-fluid">
        <div className="row mt-5">
          <div className="col-xl-8 col-md-3 col-6 d-flex mt-2" style={{ justifyContent: 'center' }}>
            <p className="font-bold" style={{ fontSize: '20px', color: '#5A5A5A' }}>{pick === 'details' ? "Details" : "Current Leases"}</p>
          </div>
        </div>
      </div>
      <div classNam="conatiner">
        {pick === 'booking'
          ?
          <div>
            <div className="row mt-3">
              <div className="col-xl-3"></div>
              <div className="col-xl-2 col-md-3 col-5 ml-2 d-flex" style={{ justifyContent: 'center' }}>
                {!active ? <p className="font-bold" type="button" style={{ fontSize: '20px', color: '#F28F31' }} onClick={() => setActive(false)}>Car Leases</p>
                  : <p className="font-bold" type="button" style={{ fontSize: '20px', color: '#5A5A5A' }} onClick={() => setActive(false)}>Car Leases</p>}
              </div>
              <div className="col-xl-4 col-md-8 col-2 ml-5 d-flex" style={{ justifyContent: 'center' }}>
                {!active ? <p type="button" className="ml-5 font-bold" style={{ fontSize: '20px', color: '#5A5A5A' }} onClick={() => setActive(true)}>Equipment Leases</p>
                  : <p type="button" className="ml-5 font-bold" style={{ fontSize: '20px', color: '#F28F31' }} onClick={() => setActive(true)}>Equipment Leases</p>}
              </div>
              <div className="col-xl-2"></div>
            </div>
            <div className="row">
              <div className="col-xl-5 col-md-9 col-10 booki py-1" style={{ justifyContent: 'center', left: '28.5rem' }}>
                {!active ? <YourBooking data={setPick} booking={"Your Booking"} status={"Active"} days={"For 2 days"} Image={car} />
                  :
                  <YourBooking data={setPick} booking={"Your Booking"} status={"Active"} days={"For 2 days"} Image={crane} />
                }
              </div>
            </div>
            <div className="row">
              <div className="col-xl-5 col-md-9 col-10 booki py-1" style={{ justifyContent: 'center', left: '28.5rem' }}>
                {!active ? <YourBooking data={setPick} booking={"Your Booking"} status={"Inactive"} Image={car} />
                  :
                  <YourBooking data={setPick} booking={"Your Booking"} status={"Inactive"} Image={crane1} />
                }
              </div>
            </div>
            <div className="row">
              <div className="col-xl-5 col-md-9 col-10 booki py-1" style={{ justifyContent: 'center', left: '28.5rem' }}>
                {!active ? <YourBooking data={setPick} booking={"Your Booking"} status={"Inactive"} Image={car} />
                  :
                  <YourBooking data={setPick} booking={"Your Booking"} status={"Inactive"} Image={generator} />
                }
              </div>
            </div>
          </div>
          :
          pick === 'pickanddrop' && !active
            ?
            <PickandDrop image={car} carname={"Audi"} carprice={"$3,040"} cardetails={"(5 seaters, view all features)"} renttime={"For 2 days, 300 kms fee"} />
            :
            pick === 'pickanddrop' && active
              ?
              <PickandDrop image={crane} carname={"Equipment"} carprice={"$300"} cardetails={"( view all features)"} renttime={"For 2 days"} />
              :
              pick === 'details' && !active
                ?
                <FeaturesVehicle />
                :
              pick === 'details' && active
              ?
              <FeaturesEquipment />
              :
                null
        }
      </div>
    </div>
  )
}

export default CarLeases;
