import React from 'react';
import play from '../../assets/images/play.svg';
import '../AppButton/index.css';

function AppButton() {
  return (
    <div className="row">
      <div className="col-xl-12">
      <div class="row mt-5">
        <div className="col">
        <ul className="d-flex px-0 appbtn" style={{ position: '', marginTop: '', display: 'inline-block' }}>
        <li style={{ }}>
          <div class="download apple">
            <i class="fa fa fa-apple fa-2x"></i>
            <span class="df">Download from</span>
            <span class="dfn">App Store</span>
          </div>
        </li>
      </ul>
        </div>
        <div className="col">
        <ul className="d-flex px-0 appbtn" style={{ position: '', marginTop: '', display: 'inline-block' }}>
        <li style={{  }}>
          <div class="download android">
            {/* <i class="fa fa fa-play fa-2x"></i> */}
            <img src={play} className="fa" style={{
              width: '2rem'
            }} />
            <span class="df">Download from</span>
            <span class="dfn">Google Play</span>
          </div>
        </li>
        </ul>
        </div>
      </div>
      </div>
    </div>
  )
}

export default AppButton;
