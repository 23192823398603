import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import Buttons from '../Buttons/index.js';
import cradImg1 from '../../assets/images/suv.png';
import cradImg2 from '../../assets/images/sedan.png';
import cradImg3 from '../../assets/images/compact.png';
import cradImg4 from '../../assets/images/bus.png';
import cradImg5 from '../../assets/images/minivan.png';
import cradImg6 from '../../assets/images/mediumdutytrucks.png';
import cradImg7 from '../../assets/images/heavytrucks.png';
import cradImg8 from '../../assets/images/othertrucks.png';
import '../CardsCategory/index.css';
function CardsCategory() {
  const cardInfo = [
    {
      image: cradImg1
    },
    {
      image: cradImg2
    },
    {
      image: cradImg3
    },
    {
      image: cradImg4
    },
    {
      image: cradImg5
    },
    {
      image: cradImg6
    },
    {
      image: cradImg7
    },
    {
      image: cradImg8
    },
  ]

  const cardsrender = (card, index) => {
    return (
      <div className="col-9 col-sm-3 col-md-5 col-lg-3 d-flex mt-5" style={{ justifyContent: 'center' }}>
        <Card className="card" key={index}>
          <Card.Body>
            <div style={{ width: '12rem', height: '11rem', backgroundColor: '#F9F9F9', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none' }}>
              <div className="mt-2 mb-2" style={{ width: '10rem', backgroundColor: '', border: 'none', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card.Img variant="top" src={card.image} />
              </div>
            </div>
            <Buttons
              className={"font-medium"}
              style={{
                color: 'white',
                backgroundColor: '#F28F31',
                border: 'none',
                width: '12rem',
                height: '3rem',
                borderRadius: '9px',
                marginTop: '15px'
              }}
            >
              Search
            </Buttons>
          </Card.Body>
        </Card>
      </div>
    )
  }
  return (
    <>
      <Container fluid d-flex style={{ justifyContent: 'center' }}>
        <Row className="d-flex" style={{ justifyContent: 'center' }}>
          {cardInfo.map(cardsrender)}
        </Row>
      </Container>
    </>
  )
}

export default CardsCategory;
