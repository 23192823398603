import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import Buttons from '../../Buttons/index.js';
import Logo from '../../../assets/images/Logo.png';
import {Container } from 'react-bootstrap';
import CarFeatures from '../../FeaturesVehicle/index.js';
import BottomTabs from '../../BottomTabs/index.js';
import img from '../../../assets/images/images.jpeg';
import '../ListItems/index.css';


function ListItem(props) {
  // const [user, setUser] = useState(false);
  let user ='';
  if('user1' in localStorage) {
    user = 'a';
  }
  // console.log(user);
  return (
    <>
      <Container fluid>
        <nav class="navbar navbar-expand-lg navbar-light bg-white">
          <a class="navbar-brand" href="#"><img className="navbar-brand ms-lg-4 ms-ms-4" srcSet={Logo} alt="cdbc" /></a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <ul class="navbar-nav px-4 py-3">
              <li class="nav-item nav">
                <NavLink className="nav-link nav-link-grow-up font-medium" to="/home" >Home</NavLink>
              </li>
              <li class="nav-item disable">
                <NavLink className="nav-link nav-link-grow-up font-medium" to="/listvehicles" >List Vehicles</NavLink>
              </li>
              <li class="nav-item">
                <NavLink className="nav-link nav-link-grow-up font-medium" to="/listequipment" >List Equipment</NavLink>
              </li>
              <li class="nav-item">
                <NavLink className="nav-link nav-link-grow-up font-medium" to="/equipment">Equipment</NavLink>
              </li>
              <li class="nav-item">
                <NavLink className="nav-link nav-link-grow-up font-medium" to="/vehicles">Vehicles</NavLink>
              </li>
             {user === 'a'
             ?
             <li>
                <p className="font-medium" style={{marginTop: '7px', fontSize: '20px',   color: '#656565'}}>User Name</p>
              </li>
              :
              <li>
                <NavLink className="nav-link nav-link-grow-up font-medium" to="/signup">Sign up</NavLink>
              </li>
              }
            </ul>
              {user === 'a'
              ?
              <div>
              <img src={img} type="button" style={{borderRadius: '50px', height: '40px', width: '40px', objectFit: 'contain'}} data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">

              </img>
              </div>
              :
              <NavLink to="/login">
                <Buttons
                  className={"font-medium"}
                  style={{
                    color: 'white',
                    backgroundColor: '#F28F31',
                    border: 'none',
                    borderRadius: '10px',
                    width: '8rem',
                    height: '3.5rem'
                  }}
                >
                  Log in
            </Buttons>
              </NavLink>
              }
          </div>
        </nav>
      </Container>
    </>
  )
}

export default ListItem;
