import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink, useHistory } from 'react-router-dom';
import logo from '../../assets/images/Logo.png';
import home from '../../assets/images/home.svg';
import task from '../../assets/images/task.svg';
import user from '../../assets/images/user.svg';
import calander from '../../assets/images/calander.svg';
import img from '../../assets/images/images.jpeg'

function SideDrawer() {
  const history = useHistory();
  function logout() {
    window.localStorage.clear();
    history.push("/home");
    window.location.reload(true);
  }
  return (
    <>
      <Container fluid className="mt-5" style={{ height: '2rem' }}>
        <Row>
          <Col>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel" style={{ width: '20rem', maxWidth: '20rem' }}>
              <div className="offcanvas-header mt-5">
                <img class="navbar-brand ms-lg-4 ms-ms-4" srcSet={logo} alt="cdbc" />
              </div>
              <div className="offcanvas-body">
                <div className="row mt-3">
                  <div className="col">
                    <i class="fa fa-bars" aria-hidden="true"></i>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <p className="font-bold" style={{ fontSize: '20px', color: '#5A5A5A', lineHeight: '2.5' }}>User Name</p>
                  </div>
                  <div className="col d-flex" style={{ justifyContent: 'flex-end' }}>
                      <img src={img} style={{objectFit: 'cover', width: '40px', maxWidth: '40px', borderRadius: '50px', height: '40px'}}></img>
                  </div>
                  <hr style={{ color: 'orange', backgroundColor: 'orange', opacity: '80%', marginTop: '-0.6rem' }}></hr>
                </div>

                <div className="row">
                  <div className="col-sm-2">
                    <img src={home} style={{ height: '1.5rem' }}></img>
                  </div>
                  <div className="col-xl-8 d-flex" style={{ justifyContent: 'flex-start' }}>
                    <p className="font-ullight" style={{ fontSize: '16px', color: '#666666' }}><NavLink className="nav-link font-ullight" to="/home" style={{ fontSize: '16px', color: '#666666', fontWeight: '400', textAlign: 'start' }}>Home</NavLink></p>
                  </div>
                  <div className="col py-1" style={{ justifyContent: 'center' }}>
                    <i class="fa fa-angle-right" aria-hidden="true" style={{ color: "orange" }}></i>
                  </div>
                  <hr style={{ color: '#9B9B9B', backgroundColor: '#9B9B9B', opacity: '80%', marginTop: '-0.6rem' }}></hr>
                </div>


                <div className="row">
                  <div className="col">
                    <img src={calander} style={{ height: '1.5rem' }}></img>
                  </div>
                  <div className="col-xl-8 d-flex" style={{ justifyContent: 'flex-start' }}>
                    <p className="font-ullight" style={{ fontSize: '16px', color: '#F28F31' }}><NavLink className="nav-link font-ullight" to="/currentleases" style={{ fontSize: '16px', color: '#F28F31', fontWeight: '400', textAlign: 'start' }}>Current Leases</NavLink></p>
                  </div>
                  <div className="col py-1" style={{ justifyContent: 'center' }}>
                    <i class="fa fa-angle-right" aria-hidden="true" style={{ color: "orange" }}></i>
                  </div>
                  <hr style={{ color: '#9B9B9B', backgroundColor: '#9B9B9B', opacity: '80%', marginTop: '-0.6rem' }}></hr>
                </div>


                <div className="row">
                  <div className="col-sm-2">
                    <img src={task} style={{ height: '1.5rem' }}></img>
                  </div>
                  <div className="col-xl-8 d-flex" style={{ justifyContent: 'flex-start' }}>
                    <p className="font-ullight" style={{ fontSize: '16px', color: '#666666', lineHeight: '-4' }}><NavLink className="nav-link font-ullight" to="/yourlistings" style={{ fontSize: '16px', color: '#666666', fontWeight: '400', textAlign: 'start' }}>Your Listings</NavLink></p>
                  </div>
                  <div className="col py-1" style={{ justifyContent: 'center' }}>
                    <i class="fa fa-angle-right" aria-hidden="true" style={{ color: "orange" }}></i>
                  </div>
                  <hr style={{ color: '#9B9B9B', backgroundColor: '#9B9B9B', opacity: '80%', marginTop: '-0.6rem' }}></hr>
                </div>


                <div className="row">
                  <div className="col-sm-2">
                    <img src={user} style={{ height: '1.5rem' }}></img>
                  </div>
                  <div className="col-xl-8 d-flex" style={{ justifyContent: 'flex-start' }}>
                    <p className="font-ullight" style={{ fontSize: '16px', color: '#666666' }}><NavLink className="nav-link font-ullight" to="/profile" style={{ fontSize: '16px', color: '#666666', fontWeight: '400', textAlign: 'start' }}>Profle</NavLink></p>
                  </div>
                  <div className="col py-1" style={{ justifyContent: 'center' }}>
                    <i class="fa fa-angle-right" aria-hidden="true" style={{ color: "orange" }}></i>
                  </div>
                  <hr style={{ color: '#9B9B9B', backgroundColor: '#9B9B9B', opacity: '80%', marginTop: '-0.6rem' }}></hr>
                </div>

              </div>
              <div className="offcanvas-footer">
                <hr style={{ color: '#9B9B9B', backgroundColor: '#9B9B9B', opacity: '80%', marginTop: '-0.6rem' }}></hr>
                <div className="row">
                  <div className="col-sm-2"></div>
                  <div className="col-xl-7"><p className="font-ullight" style={{ fontSize: '16px', color: '#666666' }} type="button" onClick={() => logout()}>Sign out</p></div>
                  <div className="col d-flex" style={{ justifyContent: 'center' }}><i class="fa fa-angle-right" aria-hidden="true" style={{ color: "orange" }}></i></div>
                </div>
                <hr style={{ color: '#9B9B9B', backgroundColor: '#9B9B9B', opacity: '80%', marginTop: '-0.6rem' }}></hr>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default SideDrawer
