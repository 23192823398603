import React from 'react';
import aboutus from '../../assets/images/aboutus.png';

function AboutUs() {
  return (
      <div className="container-fluid mt-5 back" style={{ backgroundImage: `url(${aboutus})`, width: '100%', maxWidth: '100%', height: '22rem', maxHeight: 'auto' }}>
        <div className="row mt-3"></div>
        <div className="row mt-3"></div>
        <div className="row mt-5">
          <div className="col mt-5 d-flex" style={{ justifyContent: 'center' }}>
            <h1 className="font-bold" style={{ textAlign: 'center', fontSize: '50px', color: 'white' }}> About Us</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex" style={{ justifyContent: 'center' }}>
            <p className="font-medium" style={{ textAlign: 'center', fontSize: '20px', color: 'white' }}>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum <br></br> Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum</p>
          </div>
        </div>
      </div>
  )
}

export default AboutUs;
