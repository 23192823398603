import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from '../Buttons/index.js';
import { Carousel } from 'react-bootstrap';
import '../Slider/index.css';

function Slider() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <>
      <Carousel activeIndex={index} onSelect={handleSelect} indicators={true} nextIcon={false} prevIcon={false} controls={false} style={{left: '', position: 'absolute', marginTop: '5rem'}}>
        <Carousel.Item>
          <Container className="" style={{}}>
            <Row className="" style={{}}>
              <Col sm-2 md-2 lg-2 style={{}}>
                <label className="font-bold" style={{
                  fontSize: '50px',
                  color: '#404040',
                  fontWeight: 'bold'
                }}>
                  Uleases<br></br>Drive a Great Deal
                </label>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{
                  border: 'none',
                  backgroundColor: '#F28F31',
                  width: '64px',
                  height: '7px',
                }}>

                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <p className="font-medium" style={{ fontSize: '20px', color: '#656565' }}>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum<br></br> Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum</p>
              </Col>
            </Row>
            <Row>
              <Col className="font-medium">
                <Button
                
                  style={{
                    fontSize: '20px',
                    color: 'white',
                    border: 'none',
                    borderRadius: '7px',
                    backgroundColor: '#F28F31',
                    width: '205px',
                    height: '74px'
                  }}
                >
                  Learn More
                </Button>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
        <Carousel.Item>
        <Container>
            <Row>
              <Col>
                <label className="font-bold" style={{
                  fontSize: '50px',
                  color: '#404040',
                  fontWeight: 'bold'
                }}>
                  Uleases<br></br>Drive a Great Deal
                </label>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{
                  border: 'none',
                  backgroundColor: '#F28F31',
                  width: '64px',
                  height: '7px',
                }}>

                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <p className="font-medium" style={{ fontSize: '20px', color: '#656565' }}>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum<br></br> Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum</p>
              </Col>
            </Row>
            <Row>
              <Col className="font-medium">
                <Button
                  style={{
                    fontSize: '20px',
                    color: 'white',
                    border: 'none',
                    borderRadius: '7px',
                    backgroundColor: '#F28F31',
                    width: '205px',
                    height: '74px'
                  }}
                >
                  Learn More
                </Button>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
        <Carousel.Item>
        <Container>
            <Row>
              <Col>
                <label className="font-bold" style={{
                  fontSize: '50px',
                  color: '#404040',
                  fontWeight: 'bold'
                }}>
                  Uleases<br></br>Drive a Great Deal
                </label>
              </Col>
            </Row>
            <Row>
              <div className="col">
                <div style={{
                  border: 'none',
                  backgroundColor: '#F28F31',
                  width: '64px',
                  height: '7px',
                  position: 'fixed'
                }}>

                </div>
              </div>
            </Row>
            <Row className="mt-3">
              <Col>
                <p className="font-medium" style={{ fontSize: '20px', color: '#656565' }}>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum<br></br> Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum</p>
              </Col>
            </Row>
            <Row>
              <Col className="font-medium">
                <Button
                  style={{
                    fontSize: '20px',
                    color: 'white',
                    border: 'none',
                    borderRadius: '7px',
                    backgroundColor: '#F28F31',
                    width: '205px',
                    height: '74px'
                  }}
                >
                  Learn More
                </Button>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
      </Carousel>
    </>

  )
}

export default Slider;
