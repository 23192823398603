import React from 'react';
import Form from '../../components/logInForm/index.js';

function LogIn() {
  return(
    <div className="container-fluid" style={{marginTop: '5rem'}}>
      <div className="row">
        <div className="col-xl-12 d-flex" style={{justifyContent:'center'}}>
          <Form></Form>
        </div>
      </div>
    </div>
  )
}

export default LogIn;