import React from 'react';
import { Col, Card } from 'react-bootstrap';
import Buttons from '../Buttons/index.js';
import cradImg1 from '../../assets/images/suv.png';
import cradImg2 from '../../assets/images/sedan.png';
import cradImg3 from '../../assets/images/compact.png';
import cradImg4 from '../../assets/images/bus.png';
import cradImg5 from '../../assets/images/minivan.png';
import cradImg6 from '../../assets/images/mediumdutytrucks.png';
import cradImg7 from '../../assets/images/heavytrucks.png';
import cradImg8 from '../../assets/images/othertrucks.png';
import $ from 'jquery';
import '../CardSlider/index.css';

function CardSlider() {

  $('#recipeCarousel').carousel({
    interval: 1000
  })

  $('.carousel .carousel-item').each(function () {
    var minPerSlide = 3;
    var next = $(this).next();
    if (!next.length) {
      next = $(this).siblings(':first');
    }
    next.children(':first-child').clone().appendTo($(this));

    for (var i = 0; i < minPerSlide; i++) {
      next = next.next();
      if (!next.length) {
        next = $(this).siblings(':first');
      }

      next.children(':first-child').clone().appendTo($(this));
    }
  });

  return (
    <>
      <div class="container-fluid">
        <div class="row mx-auto my-auto">
          <div id="recipeCarousel" class="carousel slide w-100" data-ride="carousel">
            <div class="carousel-inner w-100" role="listbox">
              <div class="carousel-item active">
                <div class="col-sm-3 col-md-7 d-flex" style={{ justifyContent: 'center' }}>
                  <Card className="card">
                    <Card.Body>
                      <div style={{ width: '12rem', height: '11rem', backgroundColor: '#F9F9F9', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none' }}>
                        <div className="mt-2 mb-2" style={{ width: '10rem', backgroundColor: '', border: 'none', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Card.Img variant="top" src={cradImg1} />
                        </div>
                      </div>
                      <Buttons
                        style={{
                          color: 'white',
                          backgroundColor: '#F28F31',
                          border: 'none',
                          width: '12rem',
                          height: '3rem',
                          borderRadius: '9px',
                          marginTop: '15px'
                        }}
                      >
                        Search
                      </Buttons>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-sm-3 col-md-7 d-flex" style={{ justifyContent: 'center' }}>
                  <Card className="card">
                    <Card.Body>
                      <div style={{ width: '12rem', height: '11rem', backgroundColor: '#F9F9F9', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none' }}>
                        <div className="mt-2 mb-2" style={{ width: '10rem', backgroundColor: '', border: 'none', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Card.Img variant="top" src={cradImg2} />
                        </div>
                      </div>
                      <Buttons
                        style={{
                          color: 'white',
                          backgroundColor: '#F28F31',
                          border: 'none',
                          width: '12rem',
                          height: '3rem',
                          borderRadius: '9px',
                          marginTop: '15px'
                        }}
                      >
                        Search
                      </Buttons>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-sm-3 col-md-7 d-flex" style={{ justifyContent: 'center' }}>
                  <Card className="card">
                    <Card.Body>
                      <div style={{ width: '12rem', height: '11rem', backgroundColor: '#F9F9F9', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none' }}>
                        <div className="mt-2 mb-2" style={{ width: '10rem', backgroundColor: '', border: 'none', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Card.Img variant="top" src={cradImg3} />
                        </div>
                      </div>
                      <Buttons
                        style={{
                          color: 'white',
                          backgroundColor: '#F28F31',
                          border: 'none',
                          width: '12rem',
                          height: '3rem',
                          borderRadius: '9px',
                          marginTop: '15px'
                        }}
                      >
                        Search
                      </Buttons>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-sm-3 col-md-7 d-flex" style={{ justifyContent: 'center' }}>
                  <Card className="card">
                    <Card.Body>
                      <div style={{ width: '12rem', height: '11rem', backgroundColor: '#F9F9F9', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none' }}>
                        <div className="mt-2 mb-2" style={{ width: '10rem', backgroundColor: '', border: 'none', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Card.Img variant="top" src={cradImg4} />
                        </div>
                      </div>
                      <Buttons
                        style={{
                          color: 'white',
                          backgroundColor: '#F28F31',
                          border: 'none',
                          width: '12rem',
                          height: '3rem',
                          borderRadius: '9px',
                          marginTop: '15px'
                        }}
                      >
                        Search
                      </Buttons>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-sm-3 col-md-7 d-flex" style={{ justifyContent: 'center' }}>
                  <Card className="card">
                    <Card.Body>
                      <div style={{ width: '12rem', height: '11rem', backgroundColor: '#F9F9F9', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none' }}>
                        <div className="mt-2 mb-2" style={{ width: '10rem', backgroundColor: '', border: 'none', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Card.Img variant="top" src={cradImg5} />
                        </div>
                      </div>
                      <Buttons
                        style={{
                          color: 'white',
                          backgroundColor: '#F28F31',
                          border: 'none',
                          width: '12rem',
                          height: '3rem',
                          borderRadius: '9px',
                          marginTop: '15px'
                        }}
                      >
                        Search
                      </Buttons>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-sm-3 col-md-7 d-flex" style={{ justifyContent: 'center' }}>
                  <Card className="card">
                    <Card.Body>
                      <div style={{ width: '12rem', height: '11rem', backgroundColor: '#F9F9F9', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none' }}>
                        <div className="mt-2 mb-2" style={{ width: '10rem', backgroundColor: '', border: 'none', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Card.Img variant="top" src={cradImg6} />
                        </div>
                      </div>
                      <Buttons
                        style={{
                          color: 'white',
                          backgroundColor: '#F28F31',
                          border: 'none',
                          width: '12rem',
                          height: '3rem',
                          borderRadius: '9px',
                          marginTop: '15px'
                        }}
                      >
                        Search
                      </Buttons>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-sm-3 col-md-7 d-flex" style={{ justifyContent: 'center' }}>
                  <Card className="card">
                    <Card.Body>
                      <div style={{ width: '12rem', height: '11rem', backgroundColor: '#F9F9F9', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none' }}>
                        <div className="mt-2 mb-2" style={{ width: '10rem', backgroundColor: '', border: 'none', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Card.Img variant="top" src={cradImg7} />
                        </div>
                      </div>
                      <Buttons
                        style={{
                          color: 'white',
                          backgroundColor: '#F28F31',
                          border: 'none',
                          width: '12rem',
                          height: '3rem',
                          borderRadius: '9px',
                          marginTop: '15px'
                        }}
                      >
                        Search
                      </Buttons>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-sm-3 col-md-7 d-flex" style={{ justifyContent: 'center' }}>
                  <Card className="card">
                    <Card.Body>
                      <div style={{ width: '12rem', height: '11rem', backgroundColor: '#F9F9F9', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none' }}>
                        <div className="mt-2 mb-2" style={{ width: '10rem', backgroundColor: '', border: 'none', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Card.Img variant="top" src={cradImg8} />
                        </div>
                      </div>
                      <Buttons
                        style={{
                          color: 'white',
                          backgroundColor: '#F28F31',
                          border: 'none',
                          width: '12rem',
                          height: '3rem',
                          borderRadius: '9px',
                          marginTop: '15px'
                        }}
                      >
                        Search
                      </Buttons>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      <div className="row mt-3">
          <div className="col-sm-1 d-flex mt-5 ml-3" style={{}}>
            <a class="carousel-control-prev w-auto" href="#recipeCarousel" role="button" data-slide="prev">
              <i class="fa fa-angle-left px-5" aria-hidden="true" style={{color: 'black'}}></i>
            </a>
            
            <a class="carousel-control-next w-auto" href="#recipeCarousel" role="button" data-slide="next">
            <i class="fa fa-angle-right px-2" aria-hidden="true" style={{color: 'black'}}></i>
            </a>
          </div>
        </div>
    </>
  );
}
export default CardSlider;
