import React from 'react';
import car from '../../assets/images/slidercar.png';
import '../PickAndDrop/index.css';

function PickandDrop(props) {
  const {image, carname, carprice, cardetails, renttime} = props;
  const data=[{
    carname: 'Audi',
    rent: '$3,040',
    cardetail: '(5 seater, view all features)',
    rentdetail: 'For 2 days, 300 kms fee',
    date: '5th Mar, 11 Pm to 9th Mar, 5 Pm',
    timeleft: '10 hours left',
    pick: 'Pick Location',
    drop: 'Drop Location',

  }]
  return (
    <div style={{ backgroundColor: '#F9F9F9' }}>
      <div className="container-fluid mt-3" style={{ backgroundColor: 'white' }}>
        <div className="row mt-3">
          <div className="col-xl-3"></div>
          <div className="col-xl-4">
            <div className="row">
              <div className="col col-md-7 d-flex ml-5" style={{justifyContent: 'center'}}>
                <img src={image} style={{width: '18rem', height: '10rem', objectFit :'contain', justifyContent: 'center'}}/>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-xl-6 col-md-5 ml-5">
                <div className="row"><p style={{textAlign: '', fontSize: '20px', color: '#5A5A5A'}} className="px-5 font-bold">{carname}</p></div>
                <div className="row" style={{marginTop: '-1rem'}}><p className="px-5 font-ullight" style={{fontSize: '12px', color: '#9B9B9B'}}>{cardetails}</p></div>
              </div>
              <div className="col px-0">
              <div className="row"><p className="font-bold" style={{fontSize: '20px', color :'#5A5A5A'}}>{carprice}</p></div>
                <div className="row" style={{marginTop: '-0.7rem'}}><p className="font-ullight" style={{fontSize: '12px', color: '#9B9B9B'}}>{renttime}</p></div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col px-5 ml-5">
                <p className="font-ullight" style={{fontSize: '14px', color: '#5A5A5A'}}>{data[0].date}</p>
              </div>
            </div>
            <div className="row" style={{marginTop: '-0.5rem'}}>
              <div className="col px-5 ml-5">
                <p className="font-bold" style={{fontSize: '20px', color: '#5A5A5A'}}>{data[0].timeleft}</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 picki">
            <div className="row mt-3">
              <div className="col col-md-5">
                <p className="font-bold" style={{fontSize: '20px', color: '#5A5A5A'}}>Pick and drop location</p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-1">
              <i class="fa fa-map-marker" aria-hidden="true" style={{color: '#5A5A5A'}}></i>
              </div>
              <div className="col">
                <p className="font-ullight" style={{fontSize: '14px', color: '#5A5A5A'}}>{data[0].pick}</p>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-1">
              <i class="fa fa-map-marker" aria-hidden="true" style={{color: '#5A5A5A'}}></i>
              </div>
              <div className="col">
                <p className="font-ullight" style={{fontSize: '14px', color: '#5A5A5A'}}>{data[0].drop}</p>
              </div>
            </div>
          </div>
          <div className="col-xl-2"></div>
        </div>
      </div>
    </div>
  )
}

export default PickandDrop;
