import React, { useState} from 'react';
import Inputs from '../inputs/index.js';
import Buttons from '../Buttons/index.js';
import Home from '../../screens/Home/index.js';
import '../../components/logInForm/index.css';
import CurrentLeases from '../../screens/CurrentLeases/index.js';
import ListItem from '../Navbar/ListItems/index.js';
import { useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

function LogInForm(props) {
const history = useHistory();
function logIn() {
  let email = document.getElementById('em').value;
  let password = document.getElementById('pass').value;

  let email1= localStorage.getItem('email1');
  let password1 = localStorage.getItem('password1');

  if(email === email1 && password === password1){
    alert('Welcome to Uleases!');
    history.push("/currentleases");
    window.location.reload(true);

  }
  else{
    alert('Email and Password is Incorrect!!')
  }
}
  return (
    <div>
        <div className="row">
          <div
            className="col"
            style={{
              justifyContent: 'center'
            }}>
            <div className="row">
              <div className="col d-flex px-1" style={{ justifyContent: 'flex-start' }}>
                <label
                  classname="font-bold"
                  style={{
                    color: '#5A5A5A',
                    fontSize: '20px',
                  }}
                >
                  Log in
                </label>
              </div>
            </div>
            <div className="row mt-5">
              <Inputs
                id={"em"}
                type={"email"}
                placeholder={"   Email/Phone"}
                name="input-form"
                className="inputclass font-ullight"
                // value={email}
                style={{
                  width: '321px',
                  height: '51px',
                  borderRadius: '5px',
                  backgroundColor: '#F6F6F6',
                  border: 'none',
                  outline: 'none',
                  fontSize: '14px'
                }}
              />
            </div>

            <div className="row mt-3">
              <Inputs
                id={"pass"}
                type={"password"}
                placeholder={"   Password"}
                name="input-form"
                className="inputclass"
                // value={password}
                style={{
                  width: '321px',
                  height: '51px',
                  borderRadius: '5px',
                  backgroundColor: '#F6F6F6',
                  border: 'none',
                  outline: 'none',
                  fontSize: '14px'
                }}
              />
              <span className="fa fa-fw fa-eye field-icon" id="togglePassword" type="button" style={{ color: '#00000099', marginLeft: '-40px', top: '10px', position: 'relative', zIndex: '2', float: 'left' }}></span>
            </div>

            <div className="row mt-3">
              <div className="col d-flex px-1" style={{ justifyContent: 'flex-end' }}>
                <label><a style={{ textDecoration: 'underline', color: '#F57F0E', fontSize: '14px' }}>Forgot Password?</a></label>
              </div>
            </div>
          </div>

        </div>
        <div className="row mt-3" style={{ marginTop: '' }}>
          <Buttons
            type={"submit"}
            onClick={() => {
              logIn();
              }}
            variant="warning"
            style={{
              width: '321px',
              height: '51px',
              backgroundColor: '#F28F31',
              border: 'none',
              borderRadius: '5px',
              color: 'white',
            }}>
            Log in
          </Buttons>
        </div>

        <div className="row mt-3">
          <div className="col px-0">
            <div class="form-group form-check">
              <label class="form-check-label" style={{ fontSize: '14px', color: '#5A5A5A', fontWeight: '400' }}>
                Keep me signed in
                <input class="form-check-input d-flex" type="checkbox" name="remember" style={{ top: '-1px', backgroundColor: '#F57F0E', width: '1rem', height: '1rem', }} />
              </label>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col d-flex" style={{justifyContent: 'center'}}>
            <label className="" style={{ fontSize: '14px', color: '#5A5A5A', textAlign: 'center' }}>Dont't have a Account? <NavLink to="/signup" className="ml-0" style={{ fontSize: '14px'}}><a style={{ color: '#F57F0E', textDecoration: 'underline', }} type="button">Sign up</a></NavLink></label>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col d-flex" style={{justifyContent: 'center'}}>
            <label><NavLink to="/home" style={{ fontSize: '14px', color: '#F57F0E', textDecoration: 'underline', }}><a style={{ fontSize: '14px', color: '#F57F0E', textDecoration: 'underline', }} type="button">Continue as guest?</a></NavLink></label>
          </div>
        </div>
    </div>
  );
}

export default LogInForm;