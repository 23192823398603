/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import slidercar from '../../assets/images/slidercar.png';
import crane from '../../assets/images/Equipment1.png';
import '../FeaturesVehicle/index.css';
import Buttons from '../Buttons';


function FeaturesVehicle(props) {
  // let ob = img{};
  const location = useLocation();
  console.log(location);

  let data = [{
    name: "Car name",
    transmission_type: "Car Type",
    number: "Car Type",
    no_seat: "Car Type",
    price_per_month_per_day: "Car Type"
  }, {

  }]


  const [output, setOutput] = useState('vehicles');
  // $('.carousel .vertical .item').each(function () {
  //   let next = $(this).next();
  //   if (!next.length) {
  //     next = $(this).siblings(':first')
  //   }
  //   next.children(':first-child').clone().appendTo($(this));


  //   for (var i = 1; i < 2; i++) {
  //     next = next.next();
  //     if (!next.length) {
  //       next = $(this).siblings(':first')
  //     }
  //     next.children('first-child').clone().appendTo($(this));
  //   }
  // });

  return (
    <>
      <div style={{ backgroundColor: "whitesmoke" }}>
        <div className="container-fluid" style={{ backgroundColor: 'white' }}>
          <div className="row">
            <div className="col-xl-12 col-12   d-flex" style={{ justifyContent: "center" }}>
              <div className="row d-flex" style={{ justifyContent: "center" }}>
                <div className="col-10 col-xl-5 col-lg-6 col-md-6 col-sm-6 col-xs-0">
                  <div className="row mt-5">
                    <div className="col mt-4">
                      <h6 className="font-bold" style={{ fontSize: '20px', color: "#F28F31" }}>
                        {data[0].name}
                      </h6>
                    </div>
                    <hr style={{ backgroundColor: '#9B9B9B', color: '#9B9B9B', opacity: '80%' }}></hr>
                  </div>
                  <div className="row">
                    <div className="col-xl-7" style={{ marginTop: '-0.5rem', marginBottom: '0.5rem' }}>
                      <label
                        className="font-medium"
                        style={{
                          fontSize: "14px",
                          // fontWeight: "bold",
                          color: "#666666",
                        }}
                      >
                        Transmission type
                      </label>
                    </div>
                    <div className="col-xl-5" style={{ marginTop: '-0.5rem', marginBottom: '0.5rem', textAlign: 'end' }}>
                      <label
                        className="font-ullight"
                        style={{
                          fontSize: "14px",
                          // fontWeight: "bold",
                          color: "#666666",
                        }}
                      >
                        {data[0].transmission_type}
                      </label>
                    </div>
                    <hr style={{ backgroundColor: '#9B9B9B', color: '#9B9B9B', opacity: '80%' }}></hr>
                  </div>
                  <div className="row">
                    <div className="col-xl-8" style={{ marginTop: '-0.5rem', marginBottom: '0.5rem' }}>
                      <label
                        className="font-medium"
                        style={{
                          fontSize: "14px",
                          // fontWeight: "bold",
                          color: "#666666",
                        }}
                      >
                        Identification Number
                      </label>
                    </div>
                    {/* <div className="col-2 col-xl-0 col-sm-2 mt-3"></div> */}
                    <div className="col-xl-4" style={{ marginTop: '-0.5rem', marginBottom: '0.5rem', textAlign: 'end' }}>
                      <label
                        className="font-ullight"
                        style={{
                          fontSize: "14px",
                          // fontWeight: "bold",
                          color: "#666666",
                          textAlign: 'end'
                        }}
                      >
                        {data[0].number}
                      </label>
                    </div>
                    <hr style={{ backgroundColor: '#9B9B9B', color: '#9B9B9B', opacity: '80%' }}></hr>
                  </div>
                  <div className="row">
                    <div className="col-xl-8" style={{ marginTop: '-0.5rem', marginBottom: '0.5rem' }}>
                      <label
                        className="font-medium"
                        style={{
                          fontSize: "14px",
                          // fontWeight: "bold",
                          color: "#666666",
                        }}
                      >
                        No. of Seats
                      </label>
                    </div>
                    <div className="col-xl-4" style={{ marginTop: '-0.5rem', marginBottom: '0.5rem', textAlign: 'end' }}>
                      <label
                        className="font-ullight"
                        style={{
                          fontSize: "14px",
                          // fontWeight: "bold",
                          color: "#666666",
                          textAlign: 'end'
                        }}
                      >
                        {data[0].no_seat}
                      </label>
                    </div>
                    <hr style={{ backgroundColor: '#9B9B9B', color: '#9B9B9B', opacity: '80%' }}></hr>
                  </div>
                  <div className="row">
                    <div className="col-xl-6" style={{ marginTop: '-0.5rem', marginBottom: '0.5rem' }}>
                      <label
                        className="font-medium"
                        style={{
                          fontSize: "14px",
                          // fontWeight: "bold",
                          color: "#666666",
                        }}
                      >
                        Price per month/day
                      </label>
                    </div>
                    <div className="col-3 col-xl-1 col-lg-1 mt-3"></div>
                    <div className="col-2 col-xl-1 col-lg-1 col-sm-2 mt-3"></div>
                    <div className="col col-xl-3 col-lg-3" style={{ marginTop: '-0.5rem' , textAlign: 'end'}}>
                      <label
                        className="font-ullight"
                        style={{
                          fontSize: "14px",
                          // fontWeight: "bold",
                          color: "#666666",
                        }}
                      >
                        {data[0].price_per_month_per_day}
                      </label>
                    </div>
                    <hr style={{ backgroundColor: '#9B9B9B', color: '#9B9B9B', opacity: '80%' }}></hr>
                  </div>

                </div>
                <div
                  className="col-6 col-xl-7 col-lg-6 col-md-6 col-sm-6 col-xs-0 mt-4"
                  style={{ paddingLeft: "4rem" }}
                >
                  <div className="row">
                    <div className="col-xl-4 clpi mt-5">
                      <div style={{ display: 'block' }}>
                        <img src={slidercar} style={{ width: '12rem', marginTop: '3rem' }} />
                      </div>
                    </div>
                    <div className="col-xl-6 cpi mt-4">
                      <div className="row">
                        <div className="col-xl-12 col-lg-8 d-flex" style={{ justifyContent: 'flex-end' }}>
                          <div className="row">
                            <div class="col-xl-12">
                              <a class="left carousel-control" href="#carousel-pager" role="button" data-slide="prev">
                                <i class="fa fa-angle-up d-flex" type="button" aria-hidden="true" style={{ justifyContent: 'center', fontWeight: 'bold' }}></i>
                              </a>
                              <div id="carousel-pager" class="carousel slide" data-ride="carousel" data-interval="500000000000">
                                {/* <!-- Carousel items --> */}
                                <div style={{ maxheight: '5rem', height: '15rem' }}>
                                  <div class="carousel-inner vertical">
                                    <div class="active item">
                                      <img src={slidercar} class="img-responsive" data-target="#carousel-main" data-slide-to="0" style={{ width: '4rem', height: '4rem', objectFit:'contain' }} />
                                    </div>
                                    <div class="item">
                                      <img src={slidercar} class="img-responsive" data-target="#carousel-main" data-slide-to="1" style={{ width: '4rem', height: '4rem', objectFit:'contain' }} />
                                    </div>
                                    <div class="item">
                                      <img src={slidercar} class="img-responsive" data-target="#carousel-main" data-slide-to="2" style={{ width: '4rem', height: '4rem', objectFit:'contain'  }} />
                                    </div>
                                    <div class="item">
                                      <img src={slidercar} class="img-responsive" data-target="#carousel-main" data-slide-to="2" style={{ width: '4rem', height: '4rem', objectFit:'contain'  }} />
                                    </div>
                                  </div>
                                </div>


                                {/* <!-- Controls --> */}
                              </div>
                              <a class="right carousel-control" href="#carousel-pager" role="button" data-slide="next">
                                <i class="fa fa-angle-down d-flex" aria-hidden="true" style={{ justifyContent: 'center', fontWeight: 'bold' }}></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br></br>
        </div>
        <div className="container-fluid" style={{ backgroundColor: 'orange', height: '1px' }}></div>
        <div className="container-fluid" style={{ backgroundColor: 'whitesmoke', height: '7rem' }}>
          <div className="row mt-5 d-flex" style={{ justifyContent: 'center' }}>

            <div className="col mt-4 d-flex" style={{ justifyContent: 'center' }} >
              {location.pathname === '/vehicles' ?
               <Buttons
                onClick={() => props.humza("Summary")}
                style={{
                  width: '221px',
                  height: '42px',
                  backgroundColor: '#F28F31',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  outline: 'none'
                }}
              >
                Continue
              </Buttons>
              :
              null
              }
            </div>
          </div>
        </div>
        <div className="container-fluid" style={{ backgroundColor: '#949494', height: '1px' }}></div>
      </div>
    </>
  )
}

export default FeaturesVehicle;
