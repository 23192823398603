import React from 'react';
import PickDate from '../../assets/images/PickDate.png';
import BookCar from '../../assets/images/BookCar.png';
import ChooseCar from '../../assets/images/ChooseCar.png';

function SimpleSteps() {
  return (
    <>
      <div className="container-fluid">
        <div className="row mt-5"></div>
        <div className="row mt-5">
          <div className="col d-flex mt-5" style={{ justifyContent: 'center' }}>
            <p className="font-medium" style={{ fontSize: '15px', color: '#949494' }}>How it works</p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex" style={{ justifyContent: 'center' }}>
            <h1 className="font-bold" style={{ fontSize: '50px', color: '#404040', textAlign: 'center' }}>3 Simple Steps</h1>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-xl-12">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4">
                <div className="row">
                  <div className="col d-flex" style={{ justifyContent: 'center' }}>
                    <div style={{ display: 'block' }}><img src={ChooseCar} style={{ display: 'inline-block' }}></img></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h5 className="font-medium" style={{ textAlign: 'center', fontSize: '24px', color: '#656565' }}>Pick A Car</h5>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4">
                <div className="row">
                  <div className="col d-flex" style={{ justifyContent: 'center' }}>
                    <div style={{ display: 'block' }}><img src={PickDate} style={{ display: 'inline-block' }}></img></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h5 className="font-medium" style={{ textAlign: 'center', fontSize: '24px', color: '#656565' }}>Pick Date & Time</h5>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4">
                <div className="row">
                  <div className="col d-flex" style={{ justifyContent: 'center' }}>
                    <div style={{ display: 'block' }}><img src={BookCar} style={{ display: 'inline-block' }}></img></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h5 className="font-medium" style={{ textAlign: 'center', fontSize: '24px', color: '#656565' }}>Book your car</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SimpleSteps
