import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Buttons from '../Buttons/index.js';
import Crane from '../../assets/images/crane.svg'

function ListEquipmentBanner() {
  return (
    <>
      <div>
        <Row>
          <Col className="px-5 col-xl-7 col-lg-7 d-md-none d-sm-none d-none d-xl-block">
            <img src={Crane} style={{ width: '42rem', marginTop: '-11rem' }}></img>
          </Col>
          <Col className="">
            <Row className="d-flex" style={{justifyContent: 'end'}}>
              <p 
              className={"font-medium"}
              style={{
                fontSize: '50px',
                color: '#404040'
                
              }}>
                List your Equipment
            </p>
            </Row>
            <Row className=" mt-4 d-flex" style={{justifyContent: 'center'}}>
              <Buttons
              className={"font-medium"}
               style={{
                color: 'white',
                border: 'none',
                borderRadius: '9px',
                width: '205px',
                height: '72px',
                backgroundColor: '#F28F31',
              }}>
                List Equipment
              </Buttons>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default ListEquipmentBanner;
