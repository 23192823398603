import React from 'react';
import Inputs from '../inputs/index.js';
import Buttons from '../Buttons/index.js';
import Dropdown from '../dropdown/index.js';
import '../CurrentListings/index.css';
function CurrentListings(props) {
  const data =[
    {
      id: '1',
      name: 'Automatic'
    },
    {
      id: '2',
      name: 'Manual'
    }
  ]
  return (
    <div style={{ backgroundColor: "whitesmoke"}}>
    <div className="container-fluid" style={{backgroundColor: 'whitesmoke', height: '2rem'}}></div>
      <div className="container-fluid" style={{backgroundColor:'white'}}>
        <div className="row">
          <div className="col mt-3 d-flex" style={{ justifyContent: 'center' }}>
            <div className="row d-flex" style={{ justifyContent: 'center' }}>
              <div className="col-7 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-0">
                <div className="row mt-5">
                  <div className="col-xl-8 col-10  d-flex" style={{ justifyContent: 'start' }}>
                    <h6 classNam="font-medium" style={{ color: '#5A5A5A', textAlign: 'center', fontSize: '20px', color: '#5A5A5A' }}>
                      Current Listings
                </h6>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col d-flex" style={{ justifyContent: 'center' }}>
                  <Inputs
                    className={"font-ullight"}
                    type={"text"}
                    placeholder={"   Name"}
                    style={{
                      width: '321px',
                      height :'51px',
                      backgroundColor: '#F6F6F6',
                      border: 'none',
                      borderRadius: '6px',
                      fontSize: '14px',
                      color :'#9B9B9B',
                      outline: 'none'
                    }}
                   />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col d-flex" style={{ justifyContent: 'center' }}>
                  <Inputs
                  className={"font-ullight"}
                    type={"text"}
                    placeholder={"   Rent Cost"}
                    style={{
                      width: '321px',
                      height :'51px',
                      backgroundColor: '#F6F6F6',
                      border: 'none',
                      borderRadius: '6px',
                      fontSize: '14px',
                      color :'#9B9B9B',
                      outline: 'none'
                    }}
                   />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-6">
                    <div className="row">
                      <div className="col d-flex" style={{ justifyContent: 'start' }}>
                        <label
                          className="font-ullight"
                          style={{
                            fontSize: "12px",
                            // fontWeight: "bold",
                            color: '#9B9B9B'
                            
                          }}
                        >
                          Transmission type
                </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col d-flex" style={{ justifyContent: 'start' }}>
                      <Dropdown
                        className={"font-ullight"}
                        data={data}
                        style={{
                          width: '126px',
                          height: '33px',
                          backgroundColor : '#F6F6F6',
                          borderRadius: '6px',
                          border: 'none',
                          outline: 'none',
                          color: '#9B9B9B',
                          fontSize :'12px'
                        }}
                      />
                        
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 col-5">
                    <div className="row">
                      <div className="col d-flex" style={{ justifyContent: 'start' }}>
                        <label
                          className="font-ullight"
                          style={{
                            fontSize: "12px",
                            color: '#9B9B9B'
                          }}
                        >
                          Make
                  </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col d-flex" style={{ justifyContent: 'start' }}>
                      <Dropdown 
                        className={"font-ullight"}
                        data={data}
                        style={{
                          width: '126px',
                          height: '33px',
                          backgroundColor : '#F6F6F6',
                          borderRadius: '6px',
                          border: 'none',
                          outline: 'none',
                          color: '#9B9B9B',
                          fontSize :'12px'
                        }}
                      />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <div className="col">
                        <label
                        className="font-ullight"
                          style={{
                            fontSize: "12px",
                            color: '#9B9B9B'
                          }}
                        >
                          Modal
                  </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col d-flex">
                      <Dropdown
                        className={"font-ullight"} 
                        data={data}
                        style={{
                          width: '126px',
                          height: '33px',
                          backgroundColor : '#F6F6F6',
                          borderRadius: '6px',
                          border: 'none',
                          outline: 'none',
                          color: '#9B9B9B',
                          fontSize :'12px'
                        }}
                      />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col d-flex" style={{ justifyContent: 'center' }}>
                  <Inputs
                    className={"font-ullight"}
                    type={"text"}
                    placeholder={"   Identification Number"}
                    style={{
                      width: '321px',
                      height :'51px',
                      backgroundColor: '#F6F6F6',
                      border: 'none',
                      borderRadius: '6px',
                      fontSize: '14px',
                      color :'#9B9B9B',
                      outline: 'none'
                    }}
                   />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col d-flex" style={{ justifyContent: 'center' }}>
                  <Inputs
                  className={"font-ullight"}
                    type={"text"}
                    placeholder={"   License Plate No."}
                    style={{
                      width: '321px',
                      height :'51px',
                      backgroundColor: '#F6F6F6',
                      border: 'none',
                      borderRadius: '6px',
                      fontSize: '14px',
                      color :'#9B9B9B',
                      outline: 'none'
                    }}
                   />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-xl-5">
                    <div className="row">
                      <div className="col">
                        <label
                        className="font-ullight"
                          style={{
                            fontSize: "12px",
                            color: '#9B9B9B'
                          }}
                        >
                          Available Quantity
                  </label>
                      </div>

                    </div>
                    <div className="row">
                      <div className="col d-flex" style={{ justifyContent: 'start' }}>
                      <Dropdown
                      className={"font-ullight"} 
                        data={data}
                        style={{
                          width: '126px',
                          height: '33px',
                          backgroundColor : '#F6F6F6',
                          borderRadius: '6px',
                          border: 'none',
                          outline: 'none',
                          color: '#9B9B9B',
                          fontSize :'12px'
                        }}
                      />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="row">
                      <div className="col">
                        <label
                        className="font-ullight"
                          style={{
                            fontSize: "12px",
                            color: '#9B9B9B'
                          }}
                        >
                          No. of Seats
                  </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-10 d-flex" style={{ justifyContent: 'start' }}>
                      <Dropdown
                      className={"font-ullight"} 
                        data={data}
                        style={{
                          width: '126px',
                          height: '33px',
                          backgroundColor : '#F6F6F6',
                          borderRadius: '6px',
                          border: 'none',
                          outline: 'none',
                          color: '#9B9B9B',
                          fontSize :'12px'
                        }}
                      />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-7 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-0 " style={{marginTop :'2.5rem'}}>
                <div className="row mt-5">
                  <div className="col d-flex mt-3" style={{ justifyContent: 'center' }}>
                  <span
                      class="fa fa-search field-icon"
                      style={{ color: "orange", top: '14px', position: 'relative', zIndex: '2', right: '-30px'  }}
                    ></span>
                  <Inputs
                  className={"font-ullight"}
                    type={"text"}
                    placeholder={"         Location"}
                    style={{
                      width: '321px',
                      height :'51px',
                      backgroundColor: '#F6F6F6',
                      border: 'none',
                      borderRadius: '6px',
                      fontSize: '14px',
                      color :'#9B9B9B',
                      outline: 'none'
                    }}
                   />
                    
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col" style={{ justifyContent: 'start' }}>
                    <label
                    className="font-ullight"
                      style={{
                        fontSize: "12px",
                        color: '#9B9B9B'
                      }}
                    >
                      Images of the car(min 1,max 5)
                </label>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col d-flex" style={{ justifyContent: 'center' }}>
                  <input type="file"></input>
                  <Inputs
                  className={"font-ullight"}  
                    type={"text"}
                    placeholder={""}
                    style={{
                      width: '321px',
                      height :'51px',
                      backgroundColor: '#F6F6F6',
                      border: 'none',
                      borderRadius: '6px',
                      fontSize: '14px',
                      color :'#9B9B9B',
                      outline: 'none'
                    }}
                   />
                    <span
                      class="fa fa-plus-square field-icon" type="button"
                      style={{ color: "orange", marginLeft: '-30px', top: '14px', position: 'relative', zIndex: '2' }}
                    ></span>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <label
                    className="font-ullight"
                      style={{
                        fontSize: "12px",
                        color: '#9B9B9B'
                      }}
                    >
                      proof of ownership-picture copy of vehicle title
                </label>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col d-flex" style={{ justifyContent: 'center' }}>
                  <input type="file"></input>
                  <Inputs
                  className={"font-ullight"}
                    type={"text"}
                    placeholder={""}
                    style={{
                      width: '321px',
                      height :'51px',
                      backgroundColor: '#F6F6F6',
                      border: 'none',
                      borderRadius: '6px',
                      fontSize: '14px',
                      color :'#9B9B9B',
                      outline: 'none'
                    }}
                   />
                    <span
                      class="fa fa-plus-square field-icon" type="button"
                      style={{ color: "orange", marginLeft: '-30px', top: '14px', position: 'relative', zIndex: '2' }}
                    ></span>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <label
                    className="font-ullight"
                      style={{
                        fontSize: "12px",
                        color: '#9B9B9B'
                      }}
                    >
                      picture of vehicle insurance in users name
                </label>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col d-flex" style={{ justifyContent: 'center' }}>
                  <input type="file"></input>
                  <Inputs
                  className={"font-ullight"}
                    type={"text"}
                    placeholder={""}
                    style={{
                      width: '321px',
                      height :'51px',
                      backgroundColor: '#F6F6F6',
                      border: 'none',
                      borderRadius: '6px',
                      fontSize: '14px',
                      color :'#9B9B9B',
                      outline: 'none'
                    }}
                   />
                    <span
                      class="fa fa-plus-square field-icon" type="button"
                      style={{ color: "orange", marginLeft: '-30px', top: '14px', position: 'relative', zIndex: '2' }}
                    ></span>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <label
                    className="font-ullight"
                      style={{
                        fontSize: "12px",
                        // fontWeight: "bold",
                        color: '#9B9B9B'
                      }}
                    >
                      picture of vehicle insurance in users name
                </label>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col d-flex" style={{ justifyContent: 'center' }}>
                  <input type="file"></input>
                  <Inputs
                  className={"font-ullight"}
                    type={"text"}
                    placeholder={""}
                    style={{
                      width: '321px',
                      height :'51px',
                      backgroundColor: '#F6F6F6',
                      border: 'none',
                      borderRadius: '6px',
                      fontSize: '14px',
                      color :'#9B9B9B',
                      outline: 'none'
                    }}
                   />
                    
                    <span
                      class="fa fa-plus-square field-icon"
                      type="button"
                      style={{ color: "orange", marginLeft: '-30px', top: '14px', position: 'relative', zIndex: '2'}}
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col mt-5 d-flex" style={{ justifyContent: 'center' }}>
          <Buttons
          className={"font-medium"}
          onClick={() => props.data("details")}
            type={"button"}
            style={{
              width: '321px',
              height :'52px',
              borderRadius: '4px',
              border: 'none',
              backgroundColor: '#F28F31',
              color: 'white',
              fontSize: '20px',
              outline: 'none'
            }}
          >
            Continue
          </Buttons>
          </div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </div>
      <div className="container-fluid" style={{backgroundColor: 'orange', height: '1px'}}></div>
      <div className="container-fluid" style={{backgroundColor: 'whitesmoke', height: '2rem'}}></div>
       <div className="container-fluid" style={{backgroundColor: '#949494', height: '1px'}}></div>
    </div>
  )
}

export default CurrentListings;
