import React from 'react'
import SideDrawer from '../../components/SideDrawer/index';
import CarLeases from '../../components/CarLeases/index';
import CarListing from '../../components/CarListing';

function YourListings() {
  return (
      <div style={{ backgroundColor: '#F9F9F9' }}>
          <div className="container-fluid" style={{backgroundColor: 'whitesmoke', height: '1rem', opacity: '0%'}}></div>
      <div className="container-fluid mt-5" style={{ backgroundColor: 'white' }}>
        <div className="row">
          <div classNam="col-xl-12">
            <CarListing />
          </div>
        </div>
      </div>
    </div>
  )
}

export default YourListings;
