import React,{useState} from 'react';
import {BrowserRouter, Router, Switch, Route} from 'react-router-dom';
import { useLocation } from 'react-router';
import SignUp from '../src/screens/SignUp/index.js';
import LogIn from '../src/screens/LogIn/index.js';
import Navbar from './components/Navbar/ListItems/index.js';
import Footer from '../src/components/Footer/index.js';
import Home from './screens/Home/index.js';
import Slider from '../src/components/Slider/index.js';
import BookNowTab from './components/BookNowTab/index.js';
import SideDrawer from './components/SideDrawer/index.js';
import Vehicles from './screens/Vehicles/index.js';
import Equipment from './screens/Equipment/index.js';
import ListVehicles from './screens/ListVehicles/index.js';
import ListEquipment from './screens/ListEquipment/index.js';
import SUV from './components/SUV/index.js';
import CurrentLeases from './screens/CurrentLeases/index.js';
import Example from './Example.jsx';
import YourListings from './screens/YourListings/index.js';
import Profile from './screens/Profile/index.js';


function App() {
  let user='';
  // const [user, setUser] = useState('local');
  if ('user1' in localStorage){
    user = 'a';
  }
  console.log(user);
  return (
    <BrowserRouter>
      <Navbar></Navbar>
      {user === 'a' ? <Route exact path="/" exact component={CurrentLeases}></Route>
      :<Route exact path="/" exact component={Home}></Route>
      }
      <div className=" mt-1">
        <Switch>
          <Route exact path="/login">
            <LogIn />
          </Route>
          <Route exact path="/signup">
            <SignUp />
          </Route>
          <Route exact path="/home">
            <Home />
          </Route>
          <Route exact path="/vehicles">
            <Vehicles />
          </Route>
          <Route exact path="/equipment">
            <Equipment />
          </Route>
          <Route exact path="/listvehicles">
            <ListVehicles />
          </Route>
          <Route exact path="/listequipment">
            <ListEquipment />
          </Route>
          <Route exact path="/suv">
            <SUV />
          </Route>
          <Route exact path="/currentleases">
            <CurrentLeases />
          </Route>
          <Route exact path="/yourlistings">
            <YourListings />
          </Route>
          <Route exact path="/profile">
            <Profile />
          </Route>

        </Switch>
        <SideDrawer />
      </div>
      <Footer></Footer>
    </BrowserRouter>
  );
}

export default App;
