import React, {useState} from 'react'
import CurrentListings from '../../components/CurrentListings/index.js'
import Details from '../../components/Details/index.js';

function ListVehicles() {
  const [active, setActive] = useState('carlisting');

  return (
    <>
    {
      active === 'carlisting' 
      ?
      <CurrentListings data={setActive}/>
      :
      active === 'details'
      ?
      <Details/>
      :
      null
    }
    </>
  )
}

export default ListVehicles;
