import React, {useState} from 'react';

function Dropdown(props) {
  const [data] = useState(props.data);
  const [selectedData, updateSelectedData] = useState("");

  function handleChange(event) {
    updateSelectedData(event.target.value);
    if(props.onSelectChange) props.onSelectChange(selectedData);
  }

  let options = data.map(data => (
    <option key={data.id} value={data.id}>
      {data.name}
    </option>
  ))

  const {name, className, style} = props;
  return(
    <select
      name={name}
      className={className}
      onChange={handleChange}
      style={style}
    >
      <option>+ 92</option>
      {options}
    </select>
  );
}

export default Dropdown;