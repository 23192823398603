import React from 'react';
import Buttons from '../Buttons/index.js';
import YourBooking from '../YourBooking/index.js';
import CarLeases from '../CarLeases/index.js';
import { useLocation } from 'react-router-dom';
import '../EquipmentDetails/index.css';

function EquipmentDetails() {
const location = useLocation();

  const data = [{
    name: 'Equipment Name',
    rentcost : '$100',
    images: 'Images',
    availablequantity: '5',
    description: 'Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Ipsum Lorem Ipsum Lorem Ipsum',
  }];

  return (
    <>
      <div style={{ backgroundColor: "whitesmoke" }}>
        {location.pathname === '/listequipment'
        ?
        <div className="container-fluid" style={{ backgroundColor: 'whitesmoke', height: '2rem' }}></div>
        :
        null
        }
        <div className="container-fluid" style={{ backgroundColor: 'white' }}>
          <div className="row">
          {/* <CarLeases /> */}
            <div className="col-xl-12 mt-5 d-flex" style={{ justifyContent: 'center' }}>
              <div className="row d-flex" style={{ justifyContent: 'center' }}>
                <div className="col-11 col-xl-4 col-lg-6 col-md-8 col-sm-6 col-xs-0 pcoll">
                  <div className="row mt-5">
                    <div className="col mt-4">
                      <h6 className="font-bold" style={{ color: "#5A5A5A", fontSize: '20px' }}>
                        Equipment Details
                      </h6>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col">
                      <label className="font-medium" style={{
                        fontSize: "16px",
                        color: "#666666",
                      }}>{data[0].name}</label>
                    </div>
                    <hr style={{ color: '#9B9B9B', }}></hr>
                  </div>
                  <div className="row" style={{ marginTop: '-0.5rem' }}>
                    <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                      <label className="font-medium" style={{
                        fontSize: "14px",
                        color: "#666666",
                      }}>Rent Cost</label>
                    </div>
                    <div className="col-2 mt-3">
                    </div>
                    <div className="col-2 col-sm-2 mt-3">
                    </div>
                    <div className="col-2">
                      <label className="font-ullight" style={{
                        fontSize: "14px",
                        color: "#9B9B9B",
                      }}>{data[0].rentcost}</label>
                    </div>
                    <hr style={{ color: '#9B9B9B', }}></hr>
                  </div>
                  <div className="row" style={{ marginTop: '-0.5rem' }}>
                    <div className="col-8 col-sm-7">
                      <label className="font-medium" style={{
                        fontSize: "14px",
                        color: "#666666",
                      }}>Images of the equipment</label>
                    </div>
                    <div className="col-1 mt-3">
                    </div>
                    <div className="col-1 col-sm-2 mt-3">
                    </div>
                    <div className="col-1">
                      <label className="font-ullight" style={{
                        fontSize: "14px",
                        color: "#F28F31",
                      }}>{data[0].images}</label>
                    </div>
                    <hr style={{ color: '#9B9B9B', }}></hr>
                  </div>
                  <div className="row" style={{ marginTop: '-0.5rem' }}>
                    <div className="col-5 col-xl-5 col-lg-5">
                      <label className="font-medium" style={{
                        fontSize: "14px",
                        color: "#666666",
                      }}>Available Quantity</label>
                    </div>
                    <div className="col-3 mt-3">
                    </div>
                    <div className="col-2 col-sm-2 mt-3">
                    </div>
                    <div className="col">
                      <label className="font-ullight" style={{
                        fontSize: "14px",
                        color: "#9B9B9B",
                      }}>{data[0].availablequantity}</label>
                    </div>
                    <hr style={{ color: '#9B9B9B', }}></hr>
                  </div>
                </div>
                <div className="col-11 col-xl-4 col-lg-6 col-md-8 col-sm-6 mt-5 pcol" style={{ paddingLeft: '4rem', }}>
                  <div className="row mt-5">
                    <div className="col mt-3">
                    </div>
                    <div className="col mt-3">
                    </div>
                    <div className="col mt-3">
                    </div>
                    <div className="col mt-3">
                    </div>
                    <div className="col mt-3">
                    </div>
                    <div className="col mt-3">
                      <label className="font-ullight mt-3" style={{
                        fontSize: "14px",
                        color: "#F28F31",
                      }}>Edit</label>
                    </div>
                    <hr style={{ color: '#9B9B9B', }}></hr>
                  </div>
                  <div className="row" style={{ marginTop: '-0.5rem' }}>
                    <div className="col">
                      <label className="font-medium" style={{
                        fontSize: "14px",
                        color: "#666666",
                      }}>Description</label>
                    </div>
                  </div>
                  <div className="row mt-0">
                    <div className="col">
                      <p className="font-ullight mt-1 mb-4" style={{
                        fontSize: "12px",
                        color: "#9B9B9B",
                      }}>{data[0].description}</p>
                    </div>
                    <hr style={{ color: '#9B9B9B', }}></hr>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {location.pathname === '/listequipment'
          ?
          <div className="row">
            <div className="col mt-5 d-flex" style={{ justifyContent: 'center' }}>
              <Buttons
              className={"font-medium"}
                type={"button"}
                style={{
                  width: '321px',
                  height: '52px',
                  backgroundColor: '#F28F31',
                  color: 'white',
                  borderRadius: '4px',
                  border: 'none',
                  outline: 'none',
                  fontSize: '20px'
                }}
              >
                Confirm
              </Buttons>
            </div>
          </div>
          :
          null
          }

          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
        <div className="container-fluid" style={{ backgroundColor: 'orange', height: '1px' }}></div>
        <div className="container-fluid" style={{ backgroundColor: 'whitesmoke', height: '2rem' }}></div>
        <div className="container-fluid" style={{ backgroundColor: '#949494', height: '1px' }}></div>
      </div>
    </>
  )
}

export default EquipmentDetails;
