/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useState } from 'react';
import $ from 'jquery';
import Inputs from '../inputs/index.js';
import Dropdown from '../dropdown/index.js';
import Buttons from '../Buttons/index.js';
import plus from '../../assets/images/plus.svg';
import '../signUpForm/index.css';
import { event } from 'jquery';
import { useHistory } from 'react-router-dom';

function SignUpForm() {
  // $(document).ready(function () {
  //   $('.custom-file-input input[type="file"]').change(function (e) {
  //     $(this).document.getElementById('#uploaddocs]').val(e.target.files[0].name);
  //   });
  // });

  const history = useHistory();
  function signUp() {

    let name = document.getElementById('fullname').value;
    let email = document.getElementById('email').value;
    let password = document.getElementById('pass').value;

    localStorage.setItem("user1", name);
    localStorage.setItem("email1", email);
    localStorage.setItem("password1", password);

    alert("User Signed Up Successfully !");
    history.push("/login");
  }

  const [removeInput, addInput] = useState(false);
  const data = [
    {
      id: '1',
      name: '+93'
    },
    {
      id: '2',
      name: '+94'
    },
    {
      id: '3',
      name: '+95'
    },
  ];

  return (
    <div>
      <div className="row">
        <div
          className="col"
          style={{
            justifyContent: 'center'
          }}>
          {removeInput ?
            <div className="row">
              <div className="col d-flex px-1" style={{ justifyContent: 'flex-start' }}>
                <label
                  className="font-medium"
                  style={{
                    color: '#5A5A5A',
                    fontSize: '20px',

                  }}
                >
                  Sign up as a Business user
                </label>
              </div>
            </div>
            :
            <div className="row">
              <div className="col d-flex px-1" style={{ justifyContent: 'flex-start' }}>
                <label
                  className="font-medium"
                  style={{
                    color: '#5A5A5A',
                    fontSize: '20px',
                  }}
                >
                  Sign up as a normal user
                </label>
              </div>
            </div>

          }
          <div className="row mt-5">
            <Inputs
              id={"fullname"}
              type={"text"}
              placeholder={"   Full Name"}
              name="name"
              className="inputclass font-ullight"
              required={true}
              style={{
                width: '321px',
                height: '51px',
                borderRadius: '5px',
                backgroundColor: '#F6F6F6',
                border: 'none',
                outline: 'none',
                fontSize: '14px'
              }}
            />
          </div>
          <div className="row mt-3" style={{ marginTop: '' }}>
            <Inputs
              id={"email"}
              type={"text"}
              placeholder={"   Email"}
              name="email"
              className="inputclass font-ullight"
              required={true}
              style={{
                width: '321px',
                height: '51px',
                borderRadius: '5px',
                backgroundColor: '#F6F6F6',
                border: 'none',
                outline: 'none',
                outline: 'none',
                fontSize: '14px'
              }}
            />
          </div>
          {removeInput ?
            <div className="row mt-3" style={{ marginTop: '' }}>
              <Inputs
                type={"text"}
                placeholder={"   Company Name"}
                name="input-form"
                className="inputclass font-ullight"
                style={{
                  width: '321px',
                  height: '51px',
                  borderRadius: '5px',
                  backgroundColor: '#F6F6F6',
                  border: 'none',
                  outline: 'none',
                  fontSize: '14px'
                }}
              />
            </div>
            :
            null
          }
          <div
            className="row d-flex"
            style={{ marginTop: '', justifyContent: 'flex-start' }}
          >
            <div className="col px-1">
              <div className="row" style={{}}>
                <div className="col">
                  <label
                    className="font-ullight"
                    style={{
                      color: '#9B9B9B',
                      fontSize: '12px'
                    }}
                  >
                    Country code
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Dropdown
                    data={data}
                    name="customSearch"
                    className="custom-search-select font-ulight"
                    style={{
                      width: '110px',
                      height: '33px',
                      backgroundColor: '#F6F6F6',
                      border: 'none',
                      borderRadius: '5px',
                      outline: 'none',
                      fontSize: '12px',
                      color: '#9B9B9B'
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col mt-3 d-flex" style={{ justifyContent: 'flex-start' }}>
              <Inputs
                type={"text"}
                placeholder={"   Phone"}
                name="input-form"
                className="inputclass font-ullight"
                style={{
                  width: '190px',
                  height: '51px',
                  borderRadius: '5px',
                  backgroundColor: '#F6F6F6',
                  border: 'none',
                  outline: 'none',
                  fontSize: '14px'
                }}
              />
            </div>
          </div>
          <div className="row mt-3">
            <Inputs
              id={"pass"}
              type={"text"}
              placeholder={"   Password"}
              name="password"
              className="inputclass font-ullight"
              required={true}
              style={{
                width: '321px',
                height: '51px',
                borderRadius: '5px',
                backgroundColor: '#F6F6F6',
                border: 'none',
                outline: 'none',
                fontSize: '14px'
              }}
            />
            <span className="fa fa-fw fa-eye field-icon" id="togglePassword" type="button" style={{ color: '#00000099', marginLeft: '-40px', top: '10px', position: 'relative', zIndex: '2', float: 'left' }}></span>
          </div>
          <div className="row mt-3">
          {/* <div class="custom-file-input"> */}
            <input type="file"></input>
            <Inputs
              id="uploaddocs"
              type={"text"}
              placeholder={"   Driver license"}
              name="input-form"
              className="inputclass font-ullight file"
              style={{
                width: '321px',
                height: '51px',
                borderRadius: '5px',
                backgroundColor: '#F6F6F6',
                border: 'none',
                outline: 'none',
                fontSize: '14px',
                // display: 'none'

              }}
            />
            <span class="fa fa-fw fa-plus-square field-icon" href="javascript:void(0)" type="button" id="upload" onClick="Upload(#uploaddocs)" style={{ color: 'orange', marginLeft: '-40px', top: '10px', position: 'relative', zIndex: '2', float: 'left' }} ></span>
            {/* </div> */}
          </div>
          <div className="row mt-3">
          <input type="file"></input>
            <Inputs
              type={"text"}
              placeholder={"   Insurance card pictures"}
              name="input-form"
              className="inputclass font-ullight"
              style={{
                width: '321px',
                height: '51px',
                borderRadius: '4px',
                backgroundColor: '#F6F6F6',
                border: 'none',
                outline: 'none',
                fontSize: '14px'
              }}
            />
            <span class="fa fa-fw fa-plus-square field-icon" type="button" style={{ color: 'orange', marginLeft: '-40px', top: '10px', position: 'relative', zIndex: '2', float: 'left' }} ></span>
          </div>
        </div>

      </div>
      <div className="row mt-3" style={{ marginTop: '' }}>
        <Buttons
          className={"font-bold"}
          onClick={() => signUp()}
          variant="warning"
          style={{
            width: '321px',
            height: '51px',
            backgroundColor: '#F28F31',
            border: 'none',
            borderRadius: '5px',
            color: 'white',
            fontSize: '20px'
          }}
        >
          Sign up
        </Buttons>
      </div>
      <div className="row mt-5">
        <label>Sign up as a? <a style={{ color: '#F28F31', textDecoration: 'underline' }} type="button" onClick={() => addInput(!removeInput)}>{removeInput ? 'normal User' : "business account"}</a></label>
      </div>
    </div>
  )
}

export default SignUpForm;