import React from 'react';
import slidercar from '../../assets/images/slidercar.png';
import plus from '../../assets/images/plus.svg';
import { NavLink } from 'react-router-dom';
import Buttons from '../Buttons/index.js';
import '../CurrentReservation/index.css';

function CurrentReservation(props) {
  const {src, phototitle, title, titledetails, name, renttime} = props;
  return (
    <>
      <div style={{ backgroundColor: "whitesmoke" }}>
        <div className="container-fluid" style={{ backgroundColor: 'white' }}>
          <div className="row">
            <div className="col-xl-3" style={{ backgroundColor: '' }}>

            </div>
            <div className="col-xl-3" style={{ backgroundColor: '' }}>
              <div className="row">
                <div className="col d-flex" style={{ justifyContent: 'center' }}>
                  <img src={src} style={{ width: '15rem', maxWidth: '15rem', maxHeight: '10rem', marginTop: '1rem', objectFit: 'contain' }} />
                </div>
              </div>
              <div className="row mt-3" style={{ justifyContent: '' }}>
                <div className="col" style={{}}>
                  <div className="row">
                    <div className="col-7" style={{ justifyContent: '', backgroundColor: '' }}>
                      <div className="row">
                        <p className="px-5 font-bold" style={{ fontSize: '20px', color: '#5A5A5A', textAlign: '' }}>{title}</p>
                      </div>
                      <div className="row">
                        <div className="col">
                          <p className="px-3 font-ullight" style={{ fontSize: '12px', color: '#9B9B9B', textAlign: 'end', marginTop: '-1rem' }}>({titledetails}<a style={{ textDecoration: 'underline' }}>view all features</a>)</p>
                        </div>
                      </div>
                    </div>
                    <div className="col" style={{ justifyContent: '' }}>
                      <div className="row">
                        <p className="font-bold" style={{ fontSize: '20px', color: '#5A5A5A', textAlign: '' }}>{name}</p>
                      </div>
                      <div className="row">
                        <div className="col">
                          <p className=" mr-0 font-ullight" style={{ fontSize: '12px', color: '#9B9B9B', textAlign: '', marginTop: '-1rem' }}>{renttime}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5" style={{ backgroundColor: '' }}>
              <div className="row mt-3">
                <p className="font-medium" style={{fontSize: '14px', color: '#666666'}} >{phototitle}</p>
              </div>
              <div className="row mt-4">
                <div className="col" style={{}}>
                  <div className="card" style={{ width: '119px', height: '119px', borderRadius: '6px', backgroundColor: '#F6F6F6' }}>
                    <div className="row">
                      <div className="col">
                        <p className="font-ullight" style={{ fontSize: '12px', color: '#9B9B9B', textAlign: 'center' }}>Front</p>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: '-1rem' }}>
                      <div className="col">
                        <div className="card-body">
                          <div style={{ display: 'block', textAlign: 'center' }}>
                          <input type="file"></input>
                            <img src={plus} style={{ display: 'inline-block', }}></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col" style={{}}>
                  <div className="card" style={{ width: '119px', height: '119px', borderRadius: '6px', backgroundColor: '#F6F6F6' }}>
                    <div className="row">
                      <div className="col">
                        <p className="font-ullight" style={{ fontSize: '12px', color: '#9B9B9B', textAlign: 'center' }}>Front</p>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: '-1rem' }}>
                      <div className="col">
                        <div className="card-body">
                          <div style={{ display: 'block', textAlign: 'center' }}>
                          <input type="file"></input>
                            <img src={plus} style={{ display: 'inline-block', }}></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col" style={{}}>
                  <div className="card" style={{ width: '119px', height: '119px', borderRadius: '6px', backgroundColor: '#F6F6F6' }}>
                    <div className="row">
                      <div className="col">
                        <p className="font-ullight" style={{ fontSize: '12px', color: '#9B9B9B', textAlign: 'center' }}>Front</p>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: '-1rem' }}>
                      <div className="col">
                        <div className="card-body">
                          <div style={{ display: 'block', textAlign: 'center' }}>
                          <input type="file"></input>
                            <img src={plus} style={{ display: 'inline-block', }}></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col" style={{}}>
                  <div className="card" style={{ width: '119px', height: '119px', borderRadius: '6px', backgroundColor: '#F6F6F6' }}>
                    <div className="row">
                      <div className="col">
                        <p className="font-ullight" style={{ fontSize: '12px', color: '#9B9B9B', textAlign: 'center' }}>Front</p>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: '-1rem' }}>
                      <div className="col">
                        <div className="card-body">
                          <div style={{ display: 'block', textAlign: 'center' }}>
                          <input type="file"></input>
                            <img src={plus} style={{ display: 'inline-block', }}></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col">
                  <Buttons
                  className={"font-medium"}
                    style={{
                      color: 'white',
                      backgroundColor: '#F28F31',
                      width: '321px',
                      height: '52px',
                      border: 'none',
                      borderRadius: '4px',
                      fontSize: '20px'
                    }}>
                    Drive Away
                  </Buttons>
                </div>
              </div>
            </div>
            <div className="col-xl-1" style={{ backgroundColor: '' }}>

            </div>
          </div>
          <br></br>
          <br></br>
          <div className="container-fluid" style={{ backgroundColor: 'orange', height: '1px' }}></div>
          <div className="container-fluid" style={{ backgroundColor: 'whitesmoke', height: '7rem' }}>

          </div>
          <div className="container-fluid" style={{ backgroundColor: '#949494', height: '1px' }}></div>
        </div>
      </div>
    </>
  )
}

export default CurrentReservation;
