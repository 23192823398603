import React,{useState} from 'react';
import Buttons from '../Buttons/index.js';
import slidercar from '../../assets/images/slidercar.png';
import crane from '../../assets/images/vehicle11.png';
import { useLocation } from 'react-router-dom';

function YourBooking(props) {
  const location = useLocation();
  const {booking, status, days, Image}=props;

  return (
    <div style={{ backgroundColor: 'white', boxShadow: '0px 2px 6px 2px rgb(231, 231, 231)'}}>
      <div className="row">
        <div className="col-xl-12">
          <div className="row mt-3 mb-3">
            <div className="col-xl-6 px-5">
              <div className="row">
              <div className="col">
                <p className="font-bold" style={{fontSize: '20px', color :'#5A5A5A'}}>{booking}</p>
                </div></div>
              <div className="row" style={{marginTop: '-1rem'}}>
              <div className="col">
                <p className="font-medium" style={{fontSize :'12px', color :'#8ADB8A'}}>{status}</p>
              </div></div>
              <div className="row" style={{marginTop: '-1rem'}}>
              <div className="col">
                <p className="font-ullight" style={{fontSize: '12px', color :'#9B9B9B'}}>{days}</p>
              </div></div>
              <div className="row">
              <div className="col">
              <Buttons
                  className={"font-medium"}
                  onClick={() => props.data('details')}
                  type={"button"}
                  style={{
                    width: '75px',
                    height: '32px',
                    backgroundColor: 'white',
                    color: '#F28F31',
                    fontSize: '12px',
                    border: 'none',
                    outline: 'none',
                    boxShadow: '0px 2px 6px rgb(231, 231, 231)'
                  }}
                >
                  Details
                </Buttons>
              </div>
              </div>
            </div>
            <div className="col-xl-6 d-flex" style={{justifyContent: 'center', alignItems: 'center'}}>
            {location.pathname === '/currentleases' ? <img src={Image} style={{width: '10rem', maxWidth: '10rem', height :'6rem', objectFit: 'contain'}} type="button" onClick={()=> props.data("pickanddrop")}/>
              :
              <img src={Image} style={{width: '10rem', maxWidth: '10rem', height :'6rem', objectFit: 'contain'}}/>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default YourBooking;
