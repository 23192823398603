import React, { useState } from 'react';
import Details from '../Details';
import YourBooking from '../YourBooking/index';
import Buttons from '../../components/Buttons/index';
import { useLocation } from 'react-router-dom';
import car from '../../assets/images/slidercar.png';
import crane from '../../assets/images/vehicle11.png';
import crane1 from '../../assets/images/Equipment6.png';
import generator from '../../assets/images/Equipment4.png';
import EquipmentDetails from '../EquipmentDetails';
import '../CarListing/index.css';

function CarListing() {
  const location = useLocation();
  const [pick, setPick] = useState('booking');
  const [active, setActive] = useState(false);
  return (
    <div>
      {pick === 'booking'
        ?
        <div className="container-fluid">
          <div className="row mt-5">
            <div className="col-xl-8 col-md-3 col-6 d-flex mt-2" style={{ justifyContent: 'center' }}>
              <p className="font-bold" style={{ fontSize: '20px', color: '#5A5A5A' }}>Your Booking</p>
            </div>
          </div>
        </div>
        :
        pick === 'details'
          ?
          null
          :
          null
      }

      {pick === 'booking' ?
        <div classNam="conatiner">
          <div>
            <div className="row mt-3">
              <div className="col-xl-3"></div>
              <div className="col-xl-2 col-md-3 col-5 ml-2 d-flex" style={{ justifyContent: 'center' }}>
                {!active ? <p className="font-bold" type="button" style={{ fontSize: '20px', color: '#F28F31' }} onClick={() => setActive(false)}>Car Listing</p>
                  : <p className="font-bold" type="button" style={{ fontSize: '20px', color: '#5A5A5A' }} onClick={() => setActive(false)}>Car Listing</p>}
              </div>
              <div className="col-xl-4  col-md-8 col-2 ml-5 d-flex" style={{ justifyContent: 'center' }}>
                {!active ? <p type="button" className="ml-5 font-bold" style={{ fontSize: '20px', color: '#5A5A5A' }} onClick={() => setActive(true)}>Equipment Listing</p>
                  : <p type="button" className="ml-5 font-bold" style={{ fontSize: '20px', color: '#F28F31' }} onClick={() => setActive(true)}>Equipment Listing</p>}
              </div>
              <div className="col-xl-2"></div>
            </div>
            <div className="row">
              <div className="col-xl-5 col-md-9 col-10 booki py-1" style={{ justifyContent: 'center', left: '28.5rem' }}>
                {!active ? <YourBooking data={setPick} booking={"Your Booking"} status={"Active"} days={"For 2 days"} Image={car} />
                  :
                  <YourBooking data={setPick} booking={"Your Booking"} status={"Active"} days={"For 2 days"} Image={crane} />
                }
              </div>
            </div>
            <div className="row">
              <div className="col-xl-5 col-md-9 col-10 booki py-1" style={{ justifyContent: 'center', left: '28.5rem' }}>
                {!active ? <YourBooking data={setPick} booking={"Your Booking"} status={"Inactive"} Image={car} />
                  :
                  <YourBooking data={setPick} booking={"Your Booking"} status={"Inactive"} Image={crane1} />
                }
              </div>
            </div>
            <div className="row">
              <div className="col-xl-5 col-md-9 col-10 booki py-1" style={{ justifyContent: 'center', left: '28.5rem' }}>
                {!active ? <YourBooking data={setPick} booking={"Your Booking"} status={"Inactive"} Image={car} />
                  :
                  <YourBooking data={setPick} booking={"Your Booking"} status={"Inactive"} Image={generator} />
                }
              </div>
            </div>
            {location.pathname === '/yourlistings'
              ?
              <div className="row mt-4">
                <div className="col d-flex" style={{ justifyContent: 'center' }}>
                  <Buttons
                  className={"font-medium"}
                    style={{
                      width: '321px',
                      height: '52px',
                      fontSize: '20px',
                      color: 'white',
                      backgroundColor: '#F28F31',
                      borderRadius: '4px',
                      outline: 'none',
                      border: 'none'
                    }}>
                    Add Vehicles
                  </Buttons>
                </div>
              </div>
              :
              null
            }
          </div>

        </div>
        :
        pick === 'details' && !active
          ?
          <Details />
          :
          pick === 'details' && active
            ?
            <EquipmentDetails />
            :
            null
      }
    </div>
  )
}

export default CarListing;
