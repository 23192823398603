import React from 'react';
import SideDrawer from '../../components/SideDrawer/index';
import '../Profile/index.css';

function Profile() {
  const data = [{
    name: 'User Name',
    username: 'User name',
    cartype: '06.2.2000',
    id: '#8787878787',
    number: '+033333333',
    mail: 'User@gmail.com',
    pasword: 'Password'

  }]
  return (
    <div>
      <div className="container-fluid">
        <div className="row mt-5 d-flex" style={{justifyContent: 'center'}}>
          <div className="col-xl-3"></div>
          <div className="col-xl-3 col-md-8 col-10 mt-2" style={{ justifyContent: '' }}>
            <div className="row">
              <div className="col">
                <p className="font-bold" style={{ fontSize: '20px', color: '#5A5A5A' }}>My Profile</p>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <p className="font-bold" style={{ fontSize: '20px', color: '#5A5A5A' }}>{data[0].name}</p>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <p className="font-medium" style={{ fontSize: '16px', color: '#666666' }}>Personal Information</p>
              </div>
              <hr style={{ color: '#9B9B9B', backgroundColor: '#9B9B9B' }}></hr>
            </div>
            <div className="row">
              <div className="col-xl-6">
                <p className="font-medium" style={{ fontSize: '14px', color: '#666666' }}>{data[0].username}</p>
              </div>
              <div className="col-xl-6">
                <p className="font-ullight" style={{ fontSize: '14px', color: '#9B9B9B', textAlign: 'end' }}>Your Name</p>
              </div>
              <hr style={{ color: '#9B9B9B', backgroundColor: '#9B9B9B' }}></hr>
            </div>
            <div className="row">
              <div className="col-xl-6">
                <p className="font-medium" style={{ fontSize: '14px', color: '#666666' }}>{data[0].cartype}</p>
              </div>
              <div className="col-xl-6">
                <p className="font-ullight" style={{ fontSize: '14px', color: '#9B9B9B', textAlign: 'end' }}>Car Type</p>
              </div>
              <hr style={{ color: '#9B9B9B', backgroundColor: '#9B9B9B' }}></hr>
            </div>
            <div className="row">
              <div className="col-xl-6">
                <p className="font-medium" style={{ fontSize: '14px', color: '#666666' }}>{data[0].id}</p>
              </div>
              <div className="col-xl-6">
                <p className="font-ullight" style={{ fontSize: '14px', color: '#9B9B9B', textAlign: 'end' }}>ID Number</p>
              </div>
              <hr style={{ color: '#9B9B9B', backgroundColor: '#9B9B9B' }}></hr>
            </div>
            <div className="row">
              <div className="col-xl-6">
                <p style={{ fontSize: '14px', color: '#9B9B9B' }}></p>
              </div>
              <div className="col-xl-6">
                <p className="font-ullight" style={{ fontSize: '14px', color: '#9B9B9B', textAlign: 'end' }}>Driver License</p>
              </div>
              <hr style={{ color: '#9B9B9B', backgroundColor: '#9B9B9B' }}></hr>
            </div>
            <div className="row">
              <div className="col-xl-6">
                <p style={{ fontSize: '14px', color: '#9B9B9B' }}></p>
              </div>
              <div className="col-xl-6">
                <p className="font-ullight" style={{ fontSize: '14px', color: '#9B9B9B', textAlign: 'end' }}>Insurance card pictures</p>
              </div>
              <hr style={{ color: '#9B9B9B', backgroundColor: '#9B9B9B' }}></hr>
            </div>
          </div>

          <div className="col-xl-3 col-md-8 col-12 prof mt-5" style={{ justifyContent: '', paddingLeft: '6rem' }}>
            <div className="row mt-5">
              <div className="col-xl-6 mt-4">
                <p className="font-ullight" style={{ fontSize: '14px', color: '#666666' }}>Contact Info</p>
              </div>
              <div className="col-xl-6 mt-4">
                <p className="font-bold" style={{ fontSize: '14px', color: '#F28F31', textAlign: 'end' }}>Edit</p>
              </div>
              <hr style={{ color: '#9B9B9B', backgroundColor: '#9B9B9B' }}></hr>
            </div>
            <div className="row">
              <div className="col-xl-6">
                <p className="font-medium" style={{ fontSize: '14px', color: '#666666' }}>{data[0].number}</p>
              </div>
              <div className="col-xl-6">
                <p className="font-ullight" style={{ fontSize: '14px', color: '#9B9B9B', textAlign: 'end' }}>Number</p>
              </div>
              <hr style={{ color: '#9B9B9B', backgroundColor: '#9B9B9B' }}></hr>
            </div>
            <div className="row">
              <div className="col-xl-6">
                <p className="font-medium" style={{ fontSize: '14px', color: '#666666' }}>{data[0].mail}</p>
              </div>
              <div className="col-xl-6">
                <p className="font-ullight" style={{ fontSize: '14px', color: '#9B9B9B', textAlign: 'end' }}>ID Number</p>
              </div>
              <hr style={{ color: '#9B9B9B', backgroundColor: '#9B9B9B' }}></hr>
            </div>
            <div className="row">
              <div className="col-xl-6">
                <p className="font-medium" style={{ fontSize: '14px', color: '#666666' }}>{data[0].pasword}</p>
              </div>
              <div className="col-xl-6">
                <p className="font-ullight" style={{ fontSize: '14px', color: '#9B9B9B', textAlign: 'end' }}>ID Number</p>
              </div>
              <hr style={{ color: '#9B9B9B', backgroundColor: '#9B9B9B' }}></hr>
            </div>
          </div>
          <div className="col-xl-3">

          </div>
        </div>
      </div>
      <SideDrawer />
    </div>
  )
}

export default Profile;
