import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import BottomTabs from '../../components/BottomTabs'
import CardSlider from '../../components/CardSlider';
import SideDrawer from '../../components/SideDrawer/index';

function Equipment() {
  return (
    <div>
      <div className="container-fluid" style={{backgroundColor: '#F9F9F9'}}>
        <Row className="">
          <Col className="">
            <BottomTabs />
          </Col>
        </Row>
      </div>
      {/* <CardSlider></CardSlider> */}
      </div>
  )
}

export default Equipment;
