import React, { useState } from 'react';

function Inputs(props) {
  // const [inputType] = useState(props.type)
  
  // console.log(props, "Name");

  const {id,type, name, placeholder, className, data_placeholder, style, value, onChange, required} = props
  return (
    <>
      <input 
        id={id}
        type={type}
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        className={className}
        data-placeholder={data_placeholder}
        required={required}
        style={
          style
          // width: '18rem',
          // height: '2.5rem',
          // borderRadius: '5px',
          // backgroundColor: '#F6F6F6',
          // border: 'none',
        }
        >
      </input>
    </>
  );
}

export default Inputs;