import React from 'react';
import mobile from '../../assets/images/mobile.png';
import mask from '../../assets/images/mask.png';
import AppButton from '../AppButton';
import '../DownloadUleaseAppBanner/index.css';

function DownloadUleaseAppBanner() {
  return (
    <>
        <div className="row mt-5 d-flex" style={{ justifyContent: 'center' }}>
          <div className="col-xl-11 col-xs-3 col-lg-12 col-10 col-md-11 col-sm-12  mt-5 mb-5" style={{ borderRadius: '50px', backgroundImage: `url(${mask})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', objectFit: 'contain' }}>
            <div className="row">
              <div className="col-xl-2 d-none d-xl-block" style={{ justifyContent: '' }}></div>
              <div className="col-sm-12 px-1 py-4 col-md-7 col-12 col-xl-4 mt-5 mb-5">
                <h1 className="font-medium" style={{ color: 'white', textAlign: 'initial', fontSize: '40px' }}>Download the ULEASES app for free</h1>
                <br></br>
                <div className="px-0 appbtn" style={{ marginLeft: '15px'}}>
                  <AppButton />
                </div>

              </div>
              <div className="col-xl-5 px-5 col-md-4 d-none d-sm-none d-xl-block d-md-none" >
                <div className="" style={{ height: '5rem', marginLeft: '13rem' }}>
                  <img src={mobile} style={{ marginTop: '-8.5rem', display: 'block', width: '320px', height: '674px', objectFit:'contain' }}></img>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default DownloadUleaseAppBanner;
