import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import bigSuv from '../../assets/images/bigSuv.png';
import bigEquip from '../../assets/images/bigEquip.png';
import BookNowTab from '../BookNowTab/index.js';
import { Container, Row, Col } from 'react-bootstrap';
import Filter from '../Filter/index.js';
import CardsEquipment from '../CardsEquipment/index.js';
import CardSlider from '../CardSlider/index.js';
import Buttons from '../Buttons/index.js';
import img1 from '../../assets/images/slidercar.png';
import img2 from '../../assets/images/19.svg';
import img3 from '../../assets/images/9.png';
import img4 from '../../assets/images/1.png';
import img5 from '../../assets/images/8.png';
import img6 from '../../assets/images/21.png';
import img7 from '../../assets/images/7.png';
import img8 from '../../assets/images/10.png';
import cardImg1 from '../../assets/images/Equipment1.png';
import cardImg2 from '../../assets/images/Equipment2.png';
import cardImg3 from '../../assets/images/Equipment3.png';
import cardImg4 from '../../assets/images/Equipment4.png';
import cardImg5 from '../../assets/images/Equipment5.png';
import cardImg6 from '../../assets/images/Equipment6.png';
import cardImg7 from '../../assets/images/Equipment7.png';
import cardImg8 from '../../assets/images/Equipment8.png';
import '../SUV/index.css';

function SUV(props) {

  const [active, setActive] = useState(true);

  return (
    <div style={{ backgroundColor: '#F9F9F9' }}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12" style={{ position: 'relative', textAlign: 'center', color: 'white' }}>
            {active
              ?
              <img src={bigSuv} style={{ width: '100%', maxWidth: '100%', height: '20rem', maxHeight: '20rem' }}></img>
              :
              <img src={bigEquip} style={{ width: '100%', maxWidth: '100%', height: '20rem', maxHeight: '20rem' }}></img>}
            <div className="centered font-medium" style={{ position: 'absolute', top: '20%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '93px', color: 'white' }}><p className="para">{active ? "SUV" : "Equipment"}</p></div>
            {/* {////////////......//////////////////} */}
            <div className="row d-flex" style={{ justifyContent: 'center', marginTop: '10rem', top: '50%', left: '0%', position: 'static', transform: 'translate(0%, -110%)' }}>
              <div className="col-11 boook">
                <BookNowTab />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-2 col-lg-3 col-md-3 col-4 d-col-none d-none d-lg-block d-md-block d-xl-block col-sm-3" style={{ backgroundColor: 'white', marginTop: '-8rem' }}>
            <div className="row" style={{ marginTop: '5rem' }}>
              <div className="col-xl-12 mt-5" style={{ justifyContent: '', paddingLeft: '2rem' }}>
                <p className="font-medium d-flex" style={{ fontSize: '24px', color: '#656565', justifyContent: 'center', marginLeft: '-10px' }}>Filter <i class="fa fa-filter" aria-hidden="true" style={{ color: '#656565' }}></i></p>
                <hr style={{ color: 'orange', backgroundColor: 'orange', opacity: '80%' }}></hr>
                <div className="row">
                  <div className="col d-flex" style={{ justifyContent: 'center', backgroundColor: 'white' }}>
                    <Filter />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12" style={{ backgroundColor: '#F9F9F9' }}>
            {active
              ?
              <div className="row">
                <div className="col-xl-12 col-12 d-flex" style={{ justifyContent: 'center', padding: '10px' }}>
                  <CardsEquipment img1={[img1, img2, img3, img4, img5, img6, img7, img8]} title={"Car Name"} />
                </div>
              </div>
              :
              null}

            {active
              ?
              <div>
                <div className="row mt-5">
                  <div className="col-xl-12 px-5" style={{ justifyContent: 'center', padding: '' }}>
                    <p className="font-medium" style={{ fontSize: '24px', color: '#656565' }}>Browse by category</p>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-xl-12" style={{ justifyContent: 'center', padding: '10px' }}>
                    <CardSlider />
                  </div>
                </div>
              </div>
              : null}
            <div className="row mt-5">
              <div className="col-xl-12 px-5" style={{ justifyContent: 'center', padding: '' }}>
                <p  className="font-medium" style={{ fontSize: '24px', color: '#656565' }}>Browse Equipments</p>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 d-flex" style={{ justifyContent: 'center', padding: '', marginTop: '-1rem' }}>
                {active
                  ?
                  <CardsEquipment img1={[cardImg1, cardImg2, cardImg3, cardImg4]} title={"Equipment"} />
                  :
                  <CardsEquipment img1={[cardImg1, cardImg2, cardImg3, cardImg4, cardImg5, cardImg6, cardImg7, cardImg8]} title={"Equipment"} />}
              </div>
            </div>
            {active
              ?
              null
              :
              <div>
                <div className="row mt-5">
                  <div className="col-xl-12 px-5" style={{ justifyContent: 'center', padding: '' }}>
                    <p style={{ fontSize: '24px', color: '#656565' }}>Browse by category</p>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-xl-12" style={{ justifyContent: 'center', padding: '10px' }}>
                    <CardSlider />
                  </div>
                </div>
              </div>}
            <div className="row mt-4 mb-5">
              <div className="col d-flex px-4 mr-3" style={{ justifyContent: 'flex-end' }}>
                <Buttons
                  className={"font-medium"}
                  onClick={() => setActive(false)}
                  type={"button"}
                  style={{
                    width: '150px',
                    height: '60px',
                    color: 'white',
                    fontSize: '20px',
                    border: 'none',
                    outline: 'none',
                    borderRadius: '11px',
                    backgroundColor: '#F28F31'
                  }}
                >
                  View More
                </Buttons>
              </div>
            </div>
          </div>
        </div>
        {/* <Filter/> */}
      </div>
    </div>
  )
}

export default SUV;
