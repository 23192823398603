import React from 'react';
import AppleButton from '../../assets/images/AppleButton.png';
import PlayStore from '../../assets/images/Playstore.png';
import logo from '../../assets/images/Logo.png';
import AppButton from '../AppButton';
import '../Footer/index.css';

function Footer() {
  return (
    <div className="container-fluid" style={{ backgroundColor: 'white'}}>
      <div className="row d-flex mt-4 justify-content-center">
        <div className="col-12 col-xl-8 col-lg-7 col-md-6 col-sm-9  foobtn">
          <div className="px-5 mb-3" style={{ display: 'inline-block', marginTop: '-2rem' }}>
            <AppButton />
          </div>
        </div>
        <div className="col-xl-2 mt-3">
          <div className="row">
            <div className="col">
              <p style={{fontSize: '24px', color: '#656565', textAlign: 'center'}}>Home</p>
            </div>
          </div>
          <div className="row" style={{marginTop: '-1rem'}}>
            <div className="col">
              <p style={{fontSize: '24px', color: '#656565', textAlign: 'center'}}>Vehicles</p>
            </div>
          </div>
        </div>
        <div className="col-xl-2 mt-3">
          <div className="row">
            <div className="col">
              <div style={{ display: 'block' }} className="d-flex" style={{justifyContent: 'center'}}>
                <img src={logo} style={{ width: '7rem'}}></img>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
            <p className="font-medium" style={{ fontSize: '9px', fontWeight: 'inherit', textAlign: 'center' }}>Location of the office<br></br>LoremIpsum<br></br>+9235375328</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;