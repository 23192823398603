import React from 'react'
import { Row, Col } from 'react-bootstrap';
import Buttons from '../Buttons/index.js';
import Car from '../../assets/images/19.svg'
import '../ListVehicleBanner/index.css';

function ListVehicleBanner() {
  return (
    <>
      <div>
        <Row>
          <Col className="px-5">
            <Row className="px-5">
              <p 
              className="font-medium"
              style={{
                fontSize: '50px',
                color: '#404040'
              }}>
                List your vehicle
            </p>
            </Row>
            <Row className="mt-4 px-5">
              <Buttons
              classname={"font-bold"}
               style={{
                color: 'white',
                border: 'none',
                borderRadius: '9px',
                width: '205px',
                height: '72px',
                backgroundColor: '#F28F31',
              }}>
                List Vehicle
              </Buttons>
            </Row>
          </Col>
          <Col className="px-5 col-xl-7 col-lg-7 d-md-none d-sm-none d-none d-xl-block " >
            <img className="car" src={Car} style={{width: '50rem', objectFit: 'contain'}}></img>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default ListVehicleBanner;
