/* eslint-disable jsx-a11y/alt-text */
import React,{useState} from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-bootstrap';
import DateLogo from "../../assets/images/calander.svg";
import Input from '../inputs/index.js';
import Dropdown from '../dropdown/index.js';
import Buttons from '../Buttons/index.js';
import '../StartDateTime/index.css';

function StartDateTime(props) {
  const date = [
    {
      id: '1',
      name: '+93'
    },
    {
      id: '2',
      name: '+94'
    },
    {
      id: '3',
      name: '+95'
    },
  ];

  const month = [
    {
      id: '1',
      name: 'January'
    },
    {
      id: '2',
      name: 'February'
    },
    {
      id: '3',
      name: 'March'
    },
  ];

  const year = [
    {
      id: '1',
      name: '2019'
    },
    {
      id: '2',
      name: '2020'
    },
    {
      id: '3',
      name: '2021'
    },
  ];

  return (
    <>
      <div style={{ backgroundColor: "whitesmoke" }}>
        <div className="container-fluid" style={{ backgroundColor: 'white' }}>
          <div className="row mt-3">
            <div className="col d-flex" style={{ justifyContent: 'center' }}>
              <div className="row d-flex" style={{ justifyContent: 'center' }}>
                <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-0">
                  <div className="row">
                    <div className="col mt-4">
                      <h6 className="font-medium" style={{ color: "#F28F31", fontSize: '20px' }}>
                        Start Time
                      </h6>
                      <p className="font-ullight" style={{ color: "#5A5A5A", fontSize: '17px' }}>23rd Mar, 2.30 Pm</p>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col">
                      <label className="font-ullight" style={{ color: "#656565", fontSize: '20px' }}>Select Pick up Date</label>
                      <div class="form-group row">
                        <div class="col-xl-10 col-10">
                          <span>
                            <img
                              srcSet={DateLogo}
                              style={{
                                position: "absolute",
                                zindex: 9999999,
                                marginLeft: 10,
                                fontSize: '17px',
                                marginTop: '15px'
                              }}
                            />
                          </span>
                          <Input
                            type="date"
                            className="foo font-ullight"
                            placeholder={"         23,3,2021"}
                            style={{
                              paddingLeft: '40',
                              backgroundColor: '#F6F6F6',
                              borderColor: '#F6F6F6',
                              color: '#5A5A5A',
                              fontSize: '17px',
                              width: '18rem',
                              height: '3rem',
                              border: 'none',
                              borderRadius: '6px',
                              outline: 'none',
                            }}
                          >

                          </Input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-0">
                    <div className="col">
                      <label className="font-ullight" style={{ color: "#656565", fontSize: '20px' }}>Select Pick up Time</label>
                      <div class="form-group row">
                        <div class="col-xl-10 col-10">
                          <span>
                            <img
                              srcSet={DateLogo}
                              style={{
                                position: "absolute",
                                zindex: 9999999,
                                marginLeft: 10,
                                fontSize: '17px',
                                marginTop: '15px'
                              }}
                            />
                          </span>
                          <Input
                            type="time"
                            className="foo font-ullight"
                            placeholder={"         23,3,2021"}
                            style={{
                              paddingLeft: '40',
                              backgroundColor: '#F6F6F6',
                              borderColor: '#F6F6F6',
                              color: '#5A5A5A',
                              fontSize: '17px',
                              width: '18rem',
                              height: '3rem',
                              border: 'none',
                              borderRadius: '6px',
                              outline: 'none',
                            }}
                          >

                          </Input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col">
                      <label className="font-ullight" style={{ color: "#656565", fontSize: '20px' }}>Add your age</label>
                      <div className="row" style={{ marginTop: '-0.7rem', }}>
                        <div className="col">
                          <label
                            className="font-ullight"
                            style={{
                              fontSize: "12px",
                              color: "#9B9B9B",
                            }}
                          >
                            Optional
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: '-0.6rem' }}>
                    <div className="col-xl-12 d-flex" style={{ justifyContent: 'center' }}>
                      <div className="row">
                        <div className="col-3">
                          <div class="form-group">
                            <label className="font-ullight"
                              style={{
                                fontSize: "15px",
                                color: "#9B9B9B",
                              }}>Date</label>
                            <div>
                              <Dropdown
                                data={date}
                                name="customSearch"
                                value={"100"}
                                className="custom-search-select font-ullight"
                                style={{
                                  width: '74px',
                                  height: '2rem',
                                  backgroundColor: '#F6F6F6',
                                  border: 'none',
                                  borderRadius: '5px',
                                  fontSize: '15px',
                                  color: '#9B9B9B',
                                  outline: 'none'
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div class="form-group">
                            <label className="font-ullight"
                              style={{
                                fontSize: "15px",
                                color: "#9B9B9B",
                              }}>Month</label>
                            <div>
                              <Dropdown
                                data={month}
                                name="customSearch"
                                className="custom-search-select font-ullight"
                                style={{
                                  width: '100px',
                                  height: '2rem',
                                  backgroundColor: '#F6F6F6',
                                  border: 'none',
                                  borderRadius: '5px',
                                  fontSize: '15px',
                                  color: '#9B9B9B',
                                  outline: 'none'
                                }}
                              />
                            </div>

                          </div>
                        </div>
                        <div className="col-4">
                          <div class="form-group">
                            <label className="font-ullight"
                              style={{
                                fontSize: "15px",
                                color: "#9B9B9B",
                              }}>Year</label>
                            <div>
                              <Dropdown
                                data={year}
                                name="customSearch"
                                className="custom-search-select font-ullight"
                                style={{
                                  width: '100px',
                                  height: '2rem',
                                  backgroundColor: '#F6F6F6',
                                  border: 'none',
                                  borderRadius: '5px',
                                  fontSize: '15px',
                                  color: '#9B9B9B',
                                  outline: 'none'
                                }}
                              />
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-0">
                  <div className="row">
                    <div className="col mt-4">
                      <h6 className="font-medium" style={{ color: '#656565', fontSize: '20px' }}>
                        End Time
                      </h6>
                      <p className="font-ullight" style={{ color: "#5A5A5A", fontSize: '17px' }}>24th Mar, 5 Pm</p>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col">
                      <label className="font-ullight" style={{ color: "#656565", fontSize: '20px' }}>Select End up Date</label>
                      <div class="form-group row">
                        <div class="col-xl-10 col-10">
                        <span>
                            <img
                              srcSet={DateLogo}
                              style={{
                                position: "absolute",
                                zindex: 9999999,
                                marginLeft: 10,
                                fontSize: '17px',
                                marginTop: '15px'
                              }}
                            />
                          </span>
                          <Input
                            type="date"
                            className="foo"
                            placeholder={"         23,3,2021"}
                            style={{
                              paddingLeft: '40',
                              backgroundColor: '#F6F6F6',
                              borderColor: '#F6F6F6',
                              color: '#5A5A5A',
                              fontSize: '17px',
                              width: '18rem',
                              height: '3rem',
                              border: 'none',
                              borderRadius: '6px',
                              outline: 'none',
                            }}
                          >

                          </Input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-0">
                    <div className="col">
                      <label className="font-ullight" style={{ color: "#656565", fontSize: '20px' }}>Select Pick up Time</label>
                      <div class="form-group row">
                        <div class="col-xl-10 col-10">
                        <span>
                            <img
                              srcSet={DateLogo}
                              style={{
                                position: "absolute",
                                zindex: 9999999,
                                marginLeft: 10,
                                fontSize: '17px',
                                marginTop: '15px'
                              }}
                            />
                          </span>
                          <Input
                            type="time"
                            className="foo"
                            placeholder={"         23,3,2021"}
                            style={{
                              paddingLeft: '40',
                              backgroundColor: '#F6F6F6',
                              borderColor: '#F6F6F6',
                              color: '#5A5A5A',
                              fontSize: '17px',
                              width: '18rem',
                              height: '3rem',
                              border: 'none',
                              borderRadius: '6px',
                              outline: 'none',
                            }}
                          >

                          </Input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col mt-4 d-flex" >
                      <Buttons
                      className={"font-bold"} 
                      onClick={() => {
                        props.humza("CarFeatures");
                        }}
                      style={{
                        border: 'none',
                        outline: 'none',
                        backgroundColor: '#F28F31',
                        fontSize: '20px',
                        width: '270px',
                        height: '42px',
                        color: 'white',
                        borderRadius: '4px'
                      }}>
                        Continue
                      </Buttons>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
        </div>
      </div>
      <div className="container-fluid" style={{ backgroundColor: 'orange', height: '1px' }}></div>
      <div className="container-fluid" style={{ backgroundColor: 'whitesmoke', height: '3rem' }}></div>
      <div className="container-fluid" style={{ backgroundColor: '#949494', height: '1px' }}></div>
    </>
  )
}

export default StartDateTime
