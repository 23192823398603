import React from 'react';
import { NavLink } from 'react-router-dom';
import slidercar from '../../assets/images/slidercar.png';
import signupto from '../../assets/images/signupto.svg';
import Buttons from '../Buttons/index.js';


function SummaryOfTheOrder(props) {
  const {src, carname, titledetails, rentprice, details, renttime} = props;
  const data=[{
    starttime: '5th Mar, 11 Pm',
    endtime: '9th Mar, 5 Pm'
  }]
  return (
    <>
      <div style={{ backgroundColor: "whitesmoke" }}>
        <div className="container-fluid" style={{ backgroundColor: 'white' }}>
          <div className="row">
            <div className="col-xl-2" style={{ backgroundColor: '' }}>

            </div>
            <div className="col-xl-4" style={{ backgroundColor: '' }}>
              <div className="row mt-3">
                <div className="col d-flex" style={{ justifyContent: 'center' }}>
                  <img src={src} style={{ width: '286.12px', height: '161.72px', marginTop: '3rem', objectFit: 'contain' }} />
                </div>
              </div>
              <div className="row mt-3" style={{ justifyContent: '' }}>
                <div className="col" style={{}}>
                  <div className="row">
                    <div className="col" style={{ justifyContent: '' }}>
                      <div className="row">
                        <p className="font-bold" style={{ fontSize: '20px', color: '#5A5A5A', textAlign: 'center' }}>{carname}</p>
                      </div>
                      <div className="row">
                        <p className="font-ullight" style={{ fontSize: '12px', color: '#9B9B9B', textAlign: 'end', marginTop: '-1rem' }}>({titledetails}<a style={{ textDecoration: 'underline' }}>view all features</a>)</p>
                      </div>
                    </div>
                    <div className="col" style={{ justifyContent: '' }}>
                      <div className="row">
                        <p className="font-bold" style={{ fontSize: '20px', color: '#5A5A5A', textAlign: '' }}>{rentprice}</p>
                      </div>
                      <div className="row">
                        <p className="font-ullight" style={{ fontSize: '12px', color: '#9B9B9B', textAlign: '', marginTop: '-1rem' }}>{renttime}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4" style={{ backgroundColor: '' }}>
              <div className="row mt-3" style={{}}>
                <div className="col" style={{ marginTop: '4rem' }}>
                  <p className="font-medium" style={{ fontSize: '14px', color: '#666666' }}>Pickup and drop off</p>
                </div>
              </div>
              <div className="row mt-1">
                <div className="col" style={{}}>
                  <div className="row">
                    <div className="col" style={{ justifyContent: '' }}>
                      <div className="row">
                        <p className="font-medium" style={{ fontSize: '14px', color: '#F28F31' }}>Start Time</p>
                      </div>
                      <div className="row" style={{ marginTop: '-1rem' }}>
                        <p className="font-ullight" style={{ fontSize: '14px', color: '#5A5A5A' }}>{data[0].starttime}</p>
                      </div>
                    </div>
                    <div className="col" style={{ justifyContent: '' }}>
                      <div className="row">
                        <p className="font-medium" style={{ fontSize: '14px', color: '#5A5A5A' }}>End Time</p>
                      </div>
                      <div className="row" style={{ marginTop: '-1rem' }}>
                        <p className="font-ullight" style={{ fontSize: '14px', color: '#5A5A5A' }}>{data[0].endtime}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col mt-3">
                  <p className="font-medium" style={{ fontSize: '14px', color: '#666666' }}>{details}</p>
                  <br></br>
                  <p className="font-ullight" style={{ fontSize: '12px', color: '#9B9B9B', textDecoration: 'underline', marginTop: '-2.5rem' }}>View Your Info</p>
                </div>
              </div>
            </div>
            <div className="col-xl-2" style={{ backgroundColor: '' }}>

            </div>

            <br></br>
            {/* <!-- Model --> */}
            <div
              className="modal fade"
              id="myModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
              data-backdrop="false"

            >
              <div
                class="modal-dialog modal-dialog-centered"
                role="document"

              >
                <div class="modal-content mb-5" style={{ width: '50rem', height: '20rem' }}>
                  <div class="modal-header" style={{ border: 'none', height: '1rem' }}>

                    <button
                      type="button"

                      className="close modal-title"
                      data-dismiss="modal"

                      aria-label="Close"
                      style={{ border: 'none', backgroundColor: 'white' }}
                    >
                      <i className="fa fa-times" type="button" aria-hidden="true" style={{ backgroundColor: 'white', color: 'orange' }} />

                    </button>

                  </div>
                  <div class="modal-body" style={{ marginTop: '-1rem' }}>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="row">
                            <div className="col-xl-12 d-flex" style={{ justifyContent: 'center' }}>
                              <p className="font-bold" style={{ color: '#5A5A5A', fontSize: '29px' }}> Sign up to Continue</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-12 d-flex" style={{ justifyContent: 'center' }}>
                              <div>
                                <img
                                  class="d-block w-60"
                                  src={signupto}
                                  alt="First slide"
                                  style={{ alignContent: 'center', display: 'block', height: '10rem' }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-12 d-flex" style={{ justifyContent: 'center' }}>
                              <NavLink to="/signup">
                                <Buttons
                                  width="298px"
                                  height="52px"
                                  style={{
                                    width: '298px',
                                    height: '52px',
                                    backgroundColor: '#F28F31',
                                    color: 'white',
                                    border: 'none',
                                    outline: 'none',
                                    borderRadius: '4px'
                                  }}
                                >
                                  Sign up
                                </Buttons>
                              </NavLink>
                              {/* <form class="d-flex">
                                <button
                                  class="btn btn-outline-success font-bold"
                                  type="submit"
                                  onClick={() => props.humza("Summary")}
                                  style={{
                                    width: "16rem",
                                    height: "2.7rem",
                                    backgroundColor: "#f28f31",
                                    color: "white",
                                    border: "0rem",
                                    borderRadius: "5px",
                                    alignItems: "initial",
                                    lineHeight: 0,
                                  }}
                                >
                                  <NavLink className="nav-link font-bold" to="/vehiclesfour" style={{ fontSize: '20px', color: 'white' }}>Sign up</NavLink>
                                </button> */}
                              {/* <button id="clickbtn" type="button">Close</button> */}
                              {/* </form> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid" style={{ backgroundColor: 'orange', height: '1px' }}></div>
        <div className="container-fluid" style={{ backgroundColor: 'whitesmoke', height: '7rem' }}>
          <div className="row mt-5 d-flex" style={{ justifyContent: 'center' }}>

            <div className="col mt-5 mb-3 d-flex" style={{ justifyContent: 'center' }} >
              {/* <button
            type={"button"}
              data-toggle={"modal"}
              data-target={"#myModal"}
            >
              continue
            </button> */}
              <Buttons
              className={"font-bold"}
                type={"button"}
                // data-toggle={"modal"}
                // data-target={"#myModal"}
                onClick={() => props.humza("invoice")}
                style={{
                  width: '321px',
                  height: '52px',
                  color: 'white',
                  backgroundColor: '#F28F31',
                  border: 'none',
                  borderRadius: '4px',
                  outline: 'none'
                }}>
                Continue
              </Buttons>
            </div>
          </div>
        </div>
        <br></br>
        <div className="container-fluid" style={{ backgroundColor: '#949494', height: '1px' }}></div>
      </div>
    </>
  )
}

export default SummaryOfTheOrder
