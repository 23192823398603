import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CurrentReservation from '../CurrentReservation';
// import Features from '../Features';
import Invoice from '../invoice';
import SummaryOfTheOrder from '../SummaryOfTheOrder';
import StartDateTime from '../StartDateTime/index.js';
import CarFeatures from '../FeaturesVehicle/index.js';
import EquipmentFeatures from '../FeaturesEquipment/index.js'
import {useLocation} from 'react-router-dom';
import car from '../../assets/images/slidercar.png';
import crane from '../../assets/images/vehicle11.png';

function BottomTabs(props) {
  
  const [activeTab, setActiveTab] = useState('DateTime');
  const location = useLocation();
  console.log(location.pathname);
  const [path, setPath] = useState(location.pathname);
  return (
    <div>
      <Row>
        {activeTab === 'DateTime'
          ?
          <Col>
            <div className="row">
              <ul class="nav justify-content-center">
                <div className="col-xl-2">
                  <li class="nav-item mt-3">
                    <a class="nav-link active font-medium" aria-current="page" href="#" style={{ textAlign: 'left', fontSize: '17px', color: '#656565' }}>Select Date and Time</a>
                    <div className="row d-flex">
                      <div className="col-xl-12">
                        <hr style={{ marginLeft: '1rem', color: '#F28F31', height: '1.8px', backgroundColor: '#F28F31', opacity: '80%' }}></hr>
                      </div>
                    </div>
                  </li>
                </div>
                <div className="col-xl-2">
                  <li class="nav-item mt-3">
                    {
                      path === '/vehicles'
                      ?
                      <a class="nav-link active font-medium" aria-current="page" href="#" style={{ textAlign: 'left', fontSize: '17px', color: '#656565' }}>Car Features</a>
                      :
                      path === '/equipment'
                      ?
                      <a class="nav-link active font-medium" aria-current="page" href="#" style={{ textAlign: 'left', fontSize: '17px', color: '#656565' }}>Equipment Features</a>
                      :
                      null
                    }
                    <div className="row">
                      <div className="col-xl-12">
                        <hr style={{ marginLeft: '1rem', color: '#949494', height: '1.8px', backgroundColor: '#949494', opacity: '80%' }}></hr>
                      </div>
                    </div>
                  </li>
                </div>
                <div className="col-xl-2">
                  <li class="nav-item mt-3">
                    <a class="nav-link active font-medium" aria-current="page" href="#" style={{ textAlign: 'left', fontSize: '17px', color: '#656565' }}>Summary of the order</a>
                    <div className="row">
                      <div className="col-xl-12">
                        <hr style={{ marginLeft: '1rem', color: '#949494', height: '1.8px', backgroundColor: '#949494', opacity: '80%' }}></hr>
                      </div>
                    </div>
                  </li>
                </div>
                <div className="col-xl-2">
                  <li class="nav-item mt-3">
                    <a class="nav-link active font-medium" aria-current="page" href="#" style={{ textAlign: 'left', fontSize: '17px', color: '#656565' }}>Invoice</a>
                    <div className="row">
                      <div className="col-xl-12">
                        <hr style={{ marginLeft: '1rem', color: '#949494', height: '1.8px', backgroundColor: '#949494', opacity: '80%' }}></hr>
                      </div>
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </Col>
          :
          activeTab === "CarFeatures"
          ?
          <Col>
          <div className="row">
              <ul class="nav justify-content-center">
                <div className="col-xl-2">
                  <li class="nav-item mt-3">
                    <a class="nav-link active font-medium" aria-current="page" href="#" style={{ textAlign: 'left', fontSize: '17px', color: '#656565' }}>Select Date and Time</a>
                    <div className="row d-flex">
                      <div className="col-xl-12">
                        <hr style={{ marginLeft: '1rem', color: '#949494', height: '1.8px', backgroundColor: '#949494', opacity: '80%' }}></hr>
                      </div>
                    </div>
                  </li>
                </div>
                <div className="col-xl-2">
                  <li class="nav-item mt-3">
                  {
                      path === '/vehicles'
                      ?
                      <a class="nav-link active font-medium" aria-current="page" href="#" style={{ textAlign: 'left', fontSize: '17px', color: '#656565' }}>Car Features</a>
                      :
                      path === '/equipment'
                      ?
                      <a class="nav-link active font-medium" aria-current="page" href="#" style={{ textAlign: 'left', fontSize: '17px', color: '#656565' }}>Equipment Features</a>
                      :
                      null
                    }
                    <div className="row">
                      <div className="col-xl-12">
                        <hr style={{ marginLeft: '1rem', color: '#F28F31', height: '1.8px', backgroundColor: '#F28F31', opacity: '80%' }}></hr>
                      </div>
                    </div>
                  </li>
                </div>
                <div className="col-xl-2">
                  <li class="nav-item mt-3">
                    <a class="nav-link active font-medium" aria-current="page" href="#" style={{ textAlign: 'left', fontSize: '17px', color: '#656565' }}>Summary of the order</a>
                    <div className="row">
                      <div className="col-xl-12">
                        <hr style={{ marginLeft: '1rem', color: '#949494', height: '1.8px', backgroundColor: '#949494', opacity: '80%' }}></hr>
                      </div>
                    </div>
                  </li>
                </div>
                <div className="col-xl-2">
                  <li class="nav-item mt-3">
                    <a class="nav-link active font-medium" aria-current="page" href="#" style={{ textAlign: 'left', fontSize: '17px', color: '#656565' }}>Invoice</a>
                    <div className="row">
                      <div className="col-xl-12">
                        <hr style={{ marginLeft: '1rem', color: '#949494', height: '1.8px', backgroundColor: '#949494', opacity: '80%' }}></hr>
                      </div>
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </Col>
          :
          activeTab === "Summary"
          ?
          <Col>
          <div className="row">
              <ul class="nav justify-content-center">
                <div className="col-xl-2">
                  <li class="nav-item mt-3">
                    <a class="nav-link active font-medium" aria-current="page" href="#" style={{ textAlign: 'left', fontSize: '17px', color: '#656565' }}>Select Date and Time</a>
                    <div className="row d-flex">
                      <div className="col-xl-12">
                        <hr style={{ marginLeft: '1rem', color: '#949494', height: '1.8px', backgroundColor: '#949494', opacity: '80%' }}></hr>
                      </div>
                    </div>
                  </li>
                </div>
                <div className="col-xl-2">
                  <li class="nav-item mt-3">
                  {
                      path === '/vehicles'
                      ?
                      <a class="nav-link active font-medium" aria-current="page" href="#" style={{ textAlign: 'left', fontSize: '17px', color: '#656565' }}>Car Features</a>
                      :
                      path === '/equipment'
                      ?
                      <a class="nav-link active font-medium" aria-current="page" href="#" style={{ textAlign: 'left', fontSize: '17px', color: '#656565' }}>Equipment Features</a>
                      :
                      null
                    }
                    <div className="row">
                      <div className="col-xl-12">
                        <hr style={{ marginLeft: '1rem', color: '#949494', height: '1.8px', backgroundColor: '#949494', opacity: '80%' }}></hr>
                      </div>
                    </div>
                  </li>
                </div>
                <div className="col-xl-2">
                  <li class="nav-item mt-3">
                    <a class="nav-link active font-medium" aria-current="page" href="#" style={{ textAlign: 'left', fontSize: '17px', color: '#656565' }}>Summary of the order</a>
                    <div className="row">
                      <div className="col-xl-12">
                        <hr style={{ marginLeft: '1rem', color: '#F28F31', height: '1.8px', backgroundColor: '#F28F31', opacity: '80%' }}></hr>
                      </div>
                    </div>
                  </li>
                </div>
                <div className="col-xl-2">
                  <li class="nav-item mt-3">
                    <a class="nav-link active font-medium" aria-current="page" href="#" style={{ textAlign: 'left', fontSize: '17px', color: '#656565' }}>Invoice</a>
                    <div className="row">
                      <div className="col-xl-12">
                        <hr style={{ marginLeft: '1rem', color: '#949494', height: '1.8px', backgroundColor: '#949494', opacity: '80%' }}></hr>
                      </div>
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </Col>
          :
          activeTab === "invoice"
          ?
          <Col>
          <div className="row">
              <ul class="nav justify-content-center">
                <div className="col-xl-2">
                  <li class="nav-item mt-3">
                    <a class="nav-link active font-medium" aria-current="page" href="#" style={{ textAlign: 'left', fontSize: '17px', color: '#656565' }}>Select Date and Time</a>
                    <div className="row d-flex">
                      <div className="col-xl-12">
                        <hr style={{ marginLeft: '1rem', color: '#949494', height: '1.8px', backgroundColor: '#949494', opacity: '80%' }}></hr>
                      </div>
                    </div>
                  </li>
                </div>
                <div className="col-xl-2">
                  <li class="nav-item mt-3">
                  {
                      path === '/vehicles'
                      ?
                      <a class="nav-link active font-medium" aria-current="page" href="#" style={{ textAlign: 'left', fontSize: '17px', color: '#656565' }}>Car Features</a>
                      :
                      path === '/equipment'
                      ?
                      <a class="nav-link active font-medium" aria-current="page" href="#" style={{ textAlign: 'left', fontSize: '17px', color: '#656565' }}>Equipment Features</a>
                      :
                      null
                    }
                    <div className="row">
                      <div className="col-xl-12">
                        <hr style={{ marginLeft: '1rem', color: '#949494', height: '1.8px', backgroundColor: '#949494', opacity: '80%' }}></hr>
                      </div>
                    </div>
                  </li>
                </div>
                <div className="col-xl-2">
                  <li class="nav-item mt-3">
                    <a class="nav-link active font-medium" aria-current="page" href="#" style={{ textAlign: 'left', fontSize: '17px', color: '#656565' }}>Summary of the order</a>
                    <div className="row">
                      <div className="col-xl-12">
                        <hr style={{ marginLeft: '1rem', color: '#949494', height: '1.8px', backgroundColor: '#949494', opacity: '80%' }}></hr>
                      </div>
                    </div>
                  </li>
                </div>
                <div className="col-xl-2">
                  <li class="nav-item mt-3">
                    <a class="nav-link active font-medium" aria-current="page" href="#" style={{ textAlign: 'left', fontSize: '17px', color: '#656565' }}>Invoice</a>
                    <div className="row">
                      <div className="col-xl-12">
                        <hr style={{ marginLeft: '1rem', color: '#F28F31', height: '1.8px', backgroundColor: '#F28F31', opacity: '80%' }}></hr>
                      </div>
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </Col>
          :
          activeTab === "current"
          ?
          <Col>
            <div className="row">
              <ul class="nav justify-content-center px-0">
                <div className="col-xl-8">
                  <li class="nav-item mt-3">
                    <a class="nav-link active font-medium px-5" aria-current="page" href="#" style={{ textAlign: '', fontSize: '17px', color: '#656565' }}>Current Reservation</a>
                  </li>
                </div>
              </ul>
            </div>
          </Col>
          :
          null
        }
      </Row>
      <Row>
        {
          activeTab === "DateTime"
            ?
            <Col>
              <StartDateTime humza={setActiveTab} />
            </Col>
            :
            activeTab === "CarFeatures" && path === '/vehicles'
              ?
              <Col>
                <CarFeatures humza={setActiveTab} />
              </Col>
              :
              activeTab === 'CarFeatures' && path === '/equipment'
              ?
              <Col>
                <EquipmentFeatures   humza={setActiveTab} />
              </Col>
              :
              activeTab === "Summary" && path === '/vehicles'
                ?
                <Col>
                  <SummaryOfTheOrder src={car} carname={"Audi"} titledetails={"5 seater,"} rentprice={"$3,040"} renttime={"For 2 days, 300 kms fee"} details={"Driver Details"} humza={setActiveTab} />
                </Col>
                :
                activeTab === 'Summary' && path === '/equipment'
                ?
                <Col>
                  <SummaryOfTheOrder src={crane} carname={"Equipment"} titledetails={""} rentprice={"$300"} renttime={"For 2 days"} details={"User Details"} humza={setActiveTab} />
                </Col>
                :
                activeTab === "invoice" && path === '/vehicles'
                  ?
                  <Col>
                    <Invoice src={car} title={"Audi"} titledetails={"5 seater,"} rentprice={"$3,040"} renttime={"For 2 days, 300 kms fee"} humza={setActiveTab}/>
                  </Col>
                  :
                  activeTab === 'invoice' && path === '/equipment'
                  ?
                  <Col>
                    <Invoice src={crane} title={"Equipment"} titledetails={""} rentprice={"$300"} renttime={"For 2 days"} humza={setActiveTab}/>
                  </Col>
                  :
                  activeTab === "current" && path === '/vehicles'
                  ?
                  <Col>
                    <CurrentReservation src={car} phototitle={"Upload vehicle photos"} title={"Audi"} titledetails={"5 seater,"} name={"User Name"} renttime={"For 2 days, 300 kms fee"}/>
                  </Col>
                  :
                  activeTab === "current" && path === '/equipment'
                  ?
                  <Col>
                    <CurrentReservation src={crane} phototitle={"Upload Equipment photos"} title={"Equipment"} titledetails={"  "} name={"User Name"} renttime={"For 2 days"}/>
                  </Col>
                  :
                  null
        }

      </Row>
        </div>
  );
}

export default BottomTabs;
