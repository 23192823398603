import React, {useState} from 'react'
import CurrentListingsEquipment from '../../components/CurrentListingsEquipment'
import EquipmentDetails from '../../components/EquipmentDetails';

function ListEquipment() {

  const [active, setActive] = useState('current');

  return (
    <>
      {
        active === 'current'
        ?
      <CurrentListingsEquipment data={setActive}/>
      :
      active === 'equip'
      ?
      <EquipmentDetails />
      :
      null
      }
    </>
  )
}

export default ListEquipment;
