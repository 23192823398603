import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Buttons from '../Buttons/index.js';
import cardImg1 from '../../assets/images/Equipment1.png';
import cardImg2 from '../../assets/images/Equipment2.png';
import cardImg3 from '../../assets/images/Equipment3.png';
import cardImg4 from '../../assets/images/Equipment4.png';
import '../CardsEquipment/index.css';

function CardsEquipment(props) {
  // const cardInfo = [
  //   {
  //     image: cardImg1
  //   },
  //   {
  //     image: cardImg2
  //   },
  //   {
  //     image: cardImg3
  //   },
  //   {
  //     image: cardImg4
  //   },
  // ]

  const cardInfo = props.img1

  const cardsrender = (card, index) => {
    return (
      <div className="col-12 col-sm-3 col-md-6 col-lg-3 d-flex mt-5" style={{ justifyContent: 'center' }}>
        <Card className="card" key={index}>
          {/* <Card.Img variant="top" src={cradImg} /> */}
          <Card.Body>
            <div className="" style={{ width: '13rem', height: '10rem', backgroundColor: '#F9F9F9', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none' }}>
              <div className="mt-2 mb-2 px-3" style={{ width: '11rem', backgroundColor: '', border: 'none', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card.Img variant="top" src={card} style={{ borderRadius: '10px' }} />
              </div>
            </div>
            <Row className="mt-2">
              <Col xl-12 d-flex>
                <Row>
                  <Col xl-2 className="mt-2">
                    <Row>
                      <label style={{ fontWeight: 'bold', color: '#656565' }}>{props.title}</label>
                    </Row>
                    <Row style={{ marginTop: '-8px' }}>
                      <label style={{ fontWeight: '', color: '#656565', fontSize: '11px' }}>$400.10 Per day</label>
                    </Row>
                    <Row style={{ marginTop: '-8px' }}>
                      <label style={{ fontWeight: '', color: '#656565', fontSize: '11px' }}><i class="fa fa-star" aria-hidden="true" style={{ color: 'gold' }}></i>5</label>
                    </Row>
                  </Col>
                  <Col xl-2>
                    <Row>
                      <NavLink to="/vehicles">
                        <Buttons
                          className={"font-bold"}
                          style={{
                            color: 'white',
                            backgroundColor: '#F28F31',
                            border: 'none',
                            width: '6.5rem',
                            height: '3.5rem',
                            borderRadius: '8px',
                            marginTop: '15px'
                          }}
                        >
                          Book now
                        </Buttons>
                      </NavLink>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    )
  }
  return (
    <>
      <Container fluid d-flex style={{ justifyContent: 'center' }}>
        <Row className="d-flex" style={{ justifyContent: 'center' }}>
          {cardInfo.map(cardsrender)}
        </Row>
      </Container>
    </>
  )
}

export default CardsEquipment;
