import React from 'react'
import Buttons from '../Buttons/index.js';
import car from '../../assets/images/slidercar.png';
import { useLocation } from 'react-router-dom';
import { Button } from 'bootstrap';
import '../Details/index.css';

function Details() {
  const location = useLocation();

  let data = [{
    craname: 'Car Name',
    rentcost: '300',
    transmissiontype: 'Auto',
    make: 'Make',
    modal: 'Modal',
    identificationnumber: '42524525',
    licenseplateno: 'ASD-1232',
    availablequantity: '2',
    noofseats: '5'
  }]
  return (
    <>
      <div style={{ backgroundColor: "whitesmoke" }}>
        {location.pathname === '/listvehicles' ? <div className="container-fluid" style={{ backgroundColor: 'whitesmoke', height: '2rem' }}></div> : null}
        <div className="container-fluid" style={{ backgroundColor: 'white' }}>
          <div className="row d-flex" style={{justifyContent: 'center'}}>
            <div className="col-xl-3"></div>
            <div className="col-xl-3 col-md-8 col-10" style={{ paddingRight: '60px' }}>
              <div className="row mt-5">
                <div className="col mt-5">
                  <p className="font-bold" style={{ fontSize: '20px', color: '#5A5A5A' }}>Details</p>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <p className="font-ullight" className="font-medium" style={{ fontSize: '16px', color: '#666666' }}>{data[0].craname}</p>
                </div>
                <hr></hr>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <p className="font-medium" style={{ fontSize: '14px', color: '#666666' }}>Rent Cost</p>
                </div>
                <div className="col-xl-6">
                  <p className="font-ullight" style={{ fontSize: '14px', color: '#9B9B9B', textAlign: 'end' }}>{data[0].rentcost}</p>
                </div>
                <hr></hr>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <p className="font-medium" style={{ fontSize: '14px', color: '#666666' }}>Transmission type</p>
                </div>
                <div className="col-xl-6">
                  <p className="font-ullight" style={{ fontSize: '14px', color: '#9B9B9B', textAlign: 'end' }}>{data[0].transmissiontype}</p>
                </div>
                <hr></hr>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <p className="font-medium" style={{ fontSize: '14px', color: '#666666' }}>Make</p>
                </div>
                <div className="col-xl-6">
                  <p className="font-ullight" style={{ fontSize: '14px', color: '#9B9B9B', textAlign: 'end' }}>{data[0].make}</p>
                </div>
                <hr></hr>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <p className="font-medium" style={{ fontSize: '14px', color: '#666666' }}>Model</p>
                </div>
                <div className="col-xl-6">
                  <p className="font-ullight" style={{ fontSize: '14px', color: '#9B9B9B', textAlign: 'end' }}>{data[0].modal}</p>
                </div>
                <hr></hr>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <p className="font-medium" style={{ fontSize: '14px', color: '#666666' }}>Identification Number</p>
                </div>
                <div className="col-xl-6">
                  <p className="font-ullight" style={{ fontSize: '14px', color: '#9B9B9B', textAlign: 'end' }}>{data[0].identificationnumber}</p>
                </div>
                <hr></hr>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <p className="font-medium" style={{ fontSize: '14px', color: '#666666' }}>License Plate No.</p>
                </div>
                <div className="col-xl-6">
                  <p className="font-ullight" style={{ fontSize: '14px', color: '#9B9B9B', textAlign: 'end' }}>{data[0].licenseplateno}</p>
                </div>
                <hr></hr>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <p className="font-medium" style={{ fontSize: '14px', color: '#666666' }}>Available Quantity</p>
                </div>
                <div className="col-xl-6">
                  <p className="font-ullight" style={{ fontSize: '14px', color: '#9B9B9B', textAlign: 'end' }}>{data[0].availablequantity}</p>
                </div>
                <hr></hr>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <p className="font-medium" style={{ fontSize: '14px', color: '#666666' }}>No. of Seats</p>
                </div>
                <div className="col-xl-6">
                  <p className="font-ullight" style={{ fontSize: '14px', color: '#9B9B9B', textAlign: 'end' }}>{data[0].noofseats}</p>
                </div>
                <hr></hr>
              </div>
            </div>
            <div className="col-xl-3 col-md-8 col-10 pcol" style={{ paddingLeft: '60px', marginTop: '10rem' }}>
              <div className="row">
                <div className="col-xl-6">

                </div>
                <div className="col-xl-6">
                  <p className="font-ullight" style={{ fontSize: '14px', color: '#F28F31', textAlign: 'end', }} type="button" data-toggle="modal" data-target="#exampleModalCenter">Edit</p>
                </div>
                <hr></hr>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <p className="font-medium" style={{ fontSize: '14px', color: '#666666' }}>Images of the car</p>
                </div>
                <div className="col-xl-6">
                  <p className="font-ullight" style={{ fontSize: '14px', color: '#F28F31', textAlign: 'end' }}>Images</p>
                </div>
                <hr></hr>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <p className="font-medium" style={{ fontSize: '14px', color: '#666666' }}>pictures of vehicle</p>
                </div>
                <div className="col-xl-6">
                  <p className="font-ullight" style={{ fontSize: '14px', color: '#F28F31', textAlign: 'end' }}>Images</p>
                </div>
                <hr></hr>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <p className="font-medium" style={{ fontSize: '14px', color: '#666666' }}>picture of vehicle insurance</p>
                </div>
                <div className="col-xl-6">
                  <p className="font-ullight" style={{ fontSize: '14px', color: '#F28F31', textAlign: 'end' }}>Images</p>
                </div>
                <hr></hr>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <p className="font-medium" style={{ fontSize: '14px', color: '#666666' }}>user drivers license</p>
                </div>
                <div className="col-xl-6">
                  <p className="font-ullight" style={{ fontSize: '14px', color: '#F28F31', textAlign: 'end' }}>Images</p>
                </div>
                <hr></hr>
              </div>
              {location.pathname === '/listvehicles'
                ?
                <div className="row" style={{ marginTop: '5rem'}}>
                  <div className="col-4 mt-5 btn">
                    <Buttons
                    className="font-medium"
                      type={"button"}
                      style={{
                        width: '321px',
                        height: '52px',
                        backgroundColor: '#F28F31',
                        borderRadius: '4px',
                        color: 'white',
                        fontSize: '20px',
                        border: 'none',
                        outline: 'none'
                      }}
                    >
                      Confirm
                    </Buttons>
                  </div>
                </div>
                :
                null
              }
            </div>
            <div className="col-xl-3"></div>

          </div>
          <br></br>
          <br></br>
        </div>

        {/* Modal */}
        <div
          class="modal fade"
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"

        >
          <div
            class="modal-dialog modal-dialog-centered"
            role="document"
          >
            <div class="modal-content mb-5" style={{ height: '17rem' }}>
              <div class="modal-header" style={{ border: 'none', height: '1rem' }}>
                <button
                  type="button"
                  class="close modal-title"
                  data-dismiss="modal"
                  aria-label="Close"
                  style={{ border: 'none', backgroundColor: 'white' }}
                >
                  <i class="fa fa-times" aria-hidden="true" style={{ backgroundColor: 'white', color: 'orange' }}></i>
                </button>
              </div>
              <div class="modal-body" style={{ marginTop: '-1rem' }}>
                <div
                  id="carouselExampleControls"
                  class="carousel slide"
                  data-ride="carousel"
                  data-interval="false"
                >
                  <div class="carousel-inner">
                    <div class="carousel-item mt-5 active">
                      <div className="row d-flex" style={{ justifyContent: 'center' }}>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6" style={{ justifyContent: 'center' }}>
                          <img
                            class="d-block w-100"
                            src={car}
                            alt="First slide"
                            style={{ alignContent: 'center', display: 'block' }}
                          />
                          <div className="row mb-5" >
                            <div className="col">
                              <div className="row" style={{ justifyContent: 'center' }}>
                                <div className="col d-flex" style={{ justifyContent: 'center', padding: '5px' }}>
                                  <button style={{ width: '6rem', height: '1.5rem', fontSize: '10px', border: 'none', borderRadius: '8px', backgroundColor: 'white', boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)' }}>Remove Picture</button>
                                </div>
                                <div className="col d-flex" style={{ justifyContent: 'center', padding: '5px' }}>
                                  <button style={{ width: '6rem', height: '1.5rem', fontSize: '10px', border: 'none', borderRadius: '8px', backgroundColor: 'white', boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)' }}>Add Picture</button>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div className="row d-flex mt-5" style={{ justifyContent: 'center' }}>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6" style={{ justifyContent: 'center' }}>
                          <img
                            class="d-block w-100"
                            src={car}
                            alt="Second slide"
                            style={{ alignContent: 'center', display: 'block', height: '7rem' }}
                          />
                          <div className="row mb-5" >
                            <div className="col">
                              <div className="row" style={{ justifyContent: 'center' }}>
                                <div className="col d-flex" style={{ justifyContent: 'center', padding: '5px' }}>
                                  <button style={{ width: '6rem', height: '1.5rem', fontSize: '10px', border: 'none', borderRadius: '8px', backgroundColor: 'white', boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)' }}>Remove Picture</button>
                                </div>
                                <div className="col d-flex" style={{ justifyContent: 'center', padding: '5px' }}>
                                  <button style={{ width: '6rem', height: '1.5rem', fontSize: '10px', border: 'none', borderRadius: '8px', backgroundColor: 'white', boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)' }}>Add Picture</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a
                    class="carousel-control-prev"
                    href="#carouselExampleControls"
                    role="button"
                    data-slide="prev"
                  >
                    <i class="fa fa-angle-left" aria-hidden="true" style={{ color: 'black', fontWeight: 'bold' }}></i>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a
                    class="carousel-control-next"
                    href="#carouselExampleControls"
                    role="button"
                    data-slide="next"
                  >
                    <i class="fa fa-angle-right" aria-hidden="true" style={{ color: 'black', fontWeight: 'bold' }}></i>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="container-fluid" style={{ backgroundColor: 'orange', height: '1px' }}></div>
        <div className="container-fluid" style={{ backgroundColor: 'whitesmoke', height: '2rem' }}></div>
        <div className="container-fluid" style={{ backgroundColor: '#949494', height: '1px' }}></div>
      </div>
    </>
  )
}

export default Details
